<template>
    <HeaderSite />
    <PageLoading v-if="filteredFutureMakers.length === 0" />
    <main v-if="filteredFutureMakers.length > 0">
        <header class="row">
            <h1 class="col-11 col-3-xs">Be a Future Maker</h1>
            <h4 class="col-11 col-3-xs">
                To become a FutureMaker, start by <a href="/categories">Finding Your Action</a>.
            </h4>
        </header>
        <section class="body">
            <section class="filter">
                <label v-for="category in categories">
                    <input type="checkbox" v-model="filters[category]">
                    <span class="h4">
                        <img class="plus" src="/images/plus.svg">
                        <img class="check" src="/images/check.svg">
                        {{ category }}
                    </span>
                </label>
            </section>
            <section class="content row-8 row-2-xs">
                <a class="col-2" :href="caseStudyURL(caseStudy.sector.slug, caseStudy.slug)"
                    v-for="caseStudy in filteredFutureMakers">
                    <img v-if="caseStudy.blurb_image" :src="assetURL(caseStudy.blurb_image.key)">
                    <img v-else src="/images/missing-person-black.svg">
                    <div class="pill">
                        <span>{{ caseStudy.sector.name }}</span>
                    </div>
                    <blockquote v-if="caseStudy.blurb_quote">
                        <span>&ldquo;</span><br>
                        <h6 class="h5-xs">{{ caseStudy.blurb_quote }}</h6>
                        <span>&rdquo;</span>
                    </blockquote>
                    <h6> {{ caseStudy.title }} </h6>
                </a>
            </section>
        </section>
    </main>
    <Footer />
</template>

<script>
import PageLoading from './PageLoading.vue'
import HeaderSite from './HeaderSite.vue'
import Footer from './Footer.vue'
import SocialMediaShareButtons from './SocialMediaShareButtons.vue'
import api from '@accelerator/common/crm-api.js'
import * as env from "../env.js"
import { assetURL, shareNetworks, caseStudyURL } from '../mixins'

const apiServerURL = new URL(env.API_SERVER, document.baseURI).toString()
const { sector } = api(apiServerURL)

export default {
    data() { return { filters: {}, sectors: [], futureMakers: [] } },
    components: { HeaderSite, Footer, SocialMediaShareButtons, PageLoading },
    methods: {
        assetURL,
        caseStudyURL,
        matchesActiveFilter(category) {
            if (!this.isFilterActive)
                return true
            return this.filters[category]
        }
    },
    computed: {
        shareNetworks,
        isFilterActive() {
            for (const v of Object.values(this.filters))
                if (v) return true
            return false
        },
        filteredFutureMakers() {
            return this.futureMakers
                .filter(f => this.matchesActiveFilter(f.sector.category))
                .sort((a, b) => a.sector.category.localeCompare(b.sector.category))
        },
        categories() {
            return [...new Set(this.futureMakers?.map(f => f.sector.category))]
                .sort((a, b) => a.localeCompare(b))
        }
    },
    async mounted() {
        for (const _sector of await sector.getAll()) {
            this.sectors.push(_sector)
            if (_sector.case_studies)
                for (const caseStudy of _sector.case_studies) {
                    this.futureMakers.push({
                        ...caseStudy,
                        sector: _sector
                    })
                }
        }
    }
}
</script>

<style scoped>
main {
    min-height: calc(100vh - var(--header-height));
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

header {
    width: 100%;
    box-sizing: border-box;
    margin-top: var(--gutter);
    margin-bottom: var(--column);
    gap: 0;
}

header h1 {
    margin-bottom: var(--column);
}

@media (max-width: 960px) {
    header h1 {
        margin-bottom: var(--gutter);
    }
}

section.body {
    width: 100%;
    display: flex;
    /* gap: var(--gutter); */
    padding-left: 0;
    /* background-color: purple; */
    justify-content: space-between;
}

section.filter {
    border-top: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    box-sizing: border-box;
    gap: var(--gutter);
    padding: var(--gutter);
    padding-top: var(--gutter2);
    width: 33vw;
    margin-right: var(--gutter);
}

section.filter input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
}

section.filter label span {
    font-size: 2.5vmax;
    border: 1px solid black;
    border-radius: 10em;
    padding: 0.125vmax 1.25vmax 0.125vmax 0.75vmax;
    user-select: none;
    background-color: white;
    color: black;
    transition: color 0.5s, background-color 0.5s;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5vmax;
    text-transform: capitalize;
}

section.filter label span img {
    height: 1.4vmax;
    width: 1.4vmax;
}

section.filter label input:checked+span {
    background-color: black;
    color: white;
}

section.filter label span img.plus {
    display: inline;
}

section.filter label span img.check {
    display: none;
}

section.filter label input:checked+span img.check {
    display: inline;
}

section.filter label input:checked+span img.plus {
    display: none;
}

section.filter label {
    font-family: 'Px Grotesk';
    font-size: 2vmax;
    display: flex;
    flex-wrap: wrap;
    gap: 1.2vmax;
    justify-content: space-around;
}

section.content {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gutter);
    font-family: 'Px Grotesk';
    font-size: var(--sector-body-font-size);
    border-top: 1px solid black;
    box-sizing: border-box;
    padding-top: var(--gutter2);
    padding-left: 0;
    padding-bottom: var(--column);
}

section.content>a {
    display: flex;
    flex-direction: column;
    gap: calc(var(--gutter) / 2);
    color: black;
    text-decoration: none;
    margin-bottom: var(--gutter);
}

section.content>a img {
    width: 100%;
    object-fit: cover;
}

section.content>a blockquote {
    font-family: 'Px Grotesk';
    margin-bottom: calc(var(--gutter) / 2);
}

section.content>a blockquote>span {
    font-family: 'PP Editorial Old';
    font-size: 2.5em;
}

section.content>a blockquote>span:first-of-type {
    display: block;
    height: 0;
}

section.content>a blockquote>span:last-of-type {
    display: block;
    height: 0;
}

section.content>a div.pill span {
    --breathing-space: 0.3em;
    font-size: calc(1em - var(--breathing-space));
    border-radius: 2em;
    padding: var(--breathing-space) calc(var(--breathing-space) * 2);
    border: 1px solid black;
    box-sizing: border-box;
    text-transform: uppercase;
    background-color: white;
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media (max-width: 960px) {
    section.content>a div.pill {
        display: flex;
    }

    section.content>a div.pill span {
        font-size: 2.5vmax;
        padding: 0.7vmax 1.5vmax 0.5vmax 1vmax;
    }

    header {
        margin-bottom: 0;
    }

    section.body {
        flex-direction: column;
    }

    section.filter {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        border: none;
        padding-top: var(--gutter2);
        padding-bottom: var(--gutter2);
    }

    section.content {
        flex-direction: column;
    }

    section.content>a {
        gap: calc(var(--gutter) * 0.75);
        box-sizing: border-box;
        padding-left: var(--gutter);
        padding-right: var(--gutter);
    }

    section.content>a div.pill span {
        border: 1px solid black;
    }
}
</style>