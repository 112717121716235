<template>
    <footer :class="{ border }">
        <div class="row">
            <h3 class="col-8 col-3-xs">
                Inspirations, tips and resources from FutureMakers. Sign up here.
            </h3>
            <div class="cta-container">
                <a href="/chat/new" target="_blank"><CallToActionPerson /></a>
            </div>
        </div>
        <NewsletterSignup />
    </footer>
</template>

<script>
import CallToActionPerson from './CallToActionPerson.vue';
import NewsletterSignup from './NewsletterSignup.vue';

export default {
    components: { CallToActionPerson, NewsletterSignup },
    props: {
        border: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style scoped>
footer {
    min-height: var(--footer-height);
    position: relative;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: var(--gutter) 0;
    gap: var(--gutter);
}

footer.border {
    border-top: 1px solid black;
}

footer :deep(form) {
    padding-left: var(--gutter);
}

footer :deep(form) input[type=email] {
    width: calc(var(--column) * 4 + var(--gutter) * 3);
}

@media (max-width: 960px) {
    footer :deep(form) {
        flex-direction: column;
    }

    footer :deep(form) input[type=email] {
        width: calc(var(--column) * 3 + var(--gutter) * 1);
        z-index: 2;
    }

    footer :deep(form) input[type=submit] {
        width: var(--column) !important;
    }

}
</style>