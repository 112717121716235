<template>
    <div class="signup row">
        <h5 class="col-5 col-3-xs">
            Inspirations, invitations and resources from Local Government FutureMakers.
        </h5>
        <NewsletterSignup class="signup-form col-7 col-3-xs" />
    </div>
</template>

<script>
import NewsletterSignup from './NewsletterSignup.vue';

export default {
    components: { NewsletterSignup },
}
</script>

<style scoped>
div.signup {
    padding: var(--gutter) 0 var(--gutter) var(--gutter);
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

div.signup-form {
    display: flex;
}

div.signup :deep(form) {
    justify-content: flex-end;
}

div.signup :deep(form) input[type=email] {
    width: 100%;
}

</style>