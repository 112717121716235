<template>
    <div class="chat" :style="{ '--ui-color': uiColor }">
        <div class="threads" ref="threads">
            <ChatThread @should-scroll="scrollToBottom" @choice="onChoice" :ui-color="uiColor" v-for="thread in threads"
                :source="thread.source" :messages="thread.messages" :timestamp="thread.timestamp" />
            <ChatThread :ui-color="uiColor" pending source="user" v-if="pendingMessages && pendingMessages.length > 0"
                :messages="pendingMessages" />
            <ChatThread :ui-color="uiColor" typing source="bot" v-if="typing && !transitioning" />
        </div>
        <form class="input">
            <div class="input-border">
                <textarea maxlength="500" rows="1" :disabled="enabled ? null : true" @keydown="onInputKeydown($event)"
                    @keyup="onInputKeyup()" ref="textarea" v-model="messageInput"
                    :placeholder="inputPlaceholder"></textarea>
            </div>
            <button :disabled="(enabled && !!messageInput) ? null : true" @click="emitMessage()">
                <img src="/images/send-button.png">
            </button>
        </form>
    </div>
</template>

<script>
import { DateTime } from 'luxon'
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
import ChatThread from "./ChatThread.vue"

export default {
    // TODO rename typing to remote-typing
    props: ['id', 'messages', 'pendingMessages', 'typing', 'connected', 'uiColor', 'finished'],
    components: { BeatLoader, ChatThread },
    data() {
        return {
            messageInput: "",
            localTyping: false
        }
    },
    methods: {
        onChoice(choice) {
            this.$emit('message', choice)
        },
        onInputKeydown(e) {
            if (!e.shiftKey && e.key === 'Enter') {
                e.preventDefault()
                this.emitMessage()
                // this.$refs.textarea.blur()
            }
        },
        onInputKeyup() {
            // maybe emit typing
            if (this.messageInput.length > 0 && !this.localTyping) {
                this.localTyping = true
                this.$emit('typing', this.localTyping)

            } else if (this.messageInput.length == 0 && this.localTyping) {
                this.localTyping = false
                this.$emit('typing', this.localTyping)
            }
        },
        emitMessage() {
            if (this.messageInput) {
                this.$emit('message', this.messageInput)
                this.messageInput = ""
                this.localTyping = false
                this.$emit('typing', this.localTyping)
            }
        },
        scrollToBottom() {
            this.$refs.textarea?.focus()
            setTimeout(() => {
                this.$refs.threads.scrollTop = this.$refs.threads.scrollHeight;
            }, 0)
        }

    },
    watch: {
        pendingMessages() {
            this.scrollToBottom()
        },
        typing() {
            this.scrollToBottom()
        }
    },
    computed: {
        enabled() {
            return this.connected && !this.buttonPrompt && !this.transitioning && !this.finished
        },
        buttonPrompt() {
            const lastMessage = this.messages[this.messages.length - 1]
            return (!!lastMessage?.buttons) && (!lastMessage?.buttonsOptional)
        },
        transitioning() {
            return this.messages[this.messages.length - 1]?.source === 'transition'
        },
        inputPlaceholder() {
            if (this.buttonPrompt)
                return 'Please choose from the buttons above'
            else if (this.transitioning)
                return 'Connecting to future...'
            else if (this.finished)
                return 'Conversation completed'
            if (!this.enabled)
                return 'Reconnecting to sever...'
            return 'Type here...'
        },
        iconSize() {
            return "2x"
        },
        threads() {
            const now = DateTime.now()
            // TODO break up threads based on timestamps too
            const threads = []
            let thread = null
            for (const message of this.messages) {
                if (thread?.source !== message.source) {
                    thread = { source: message.source, timestamp: 0, messages: [] }
                    threads.push(thread)
                }
                const since = message.timestamp ? DateTime.fromMillis(message.timestamp).toRelative() : ""
                thread?.messages.push({ ...message, since })
                thread.timestamp = message.timestamp
            }
            return threads
        },

    },
    mounted() {
        this.$refs.textarea?.focus()
    }
}
</script>

<style scoped>
/* theme */
div.chat {
    --ui-color: white;
    --input-text-font: 'Px Grotesk';
    --input-text-size: 2vh;
    --highlight-color: #ccc;
    --ui-padding: 1vh;
    --thread-gap: 1vh;
    --submit-button-size: 16vh;
    --input-area-height: 10vh;
}

div.chat {
    background: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: var(--ui-padding);
    box-sizing: border-box;
}

div.threads::-webkit-scrollbar {
    width: 0.75vh;
}

div.threads::-webkit-scrollbar-thumb {
    background-color: var(--ui-color);
    border-radius: 50px;
    transition: background-color 1s;
}

div.threads::-webkit-scrollbar-track {
    background-color: none;
}

div.threads {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow-y: auto;
    overflow-x: hidden;
    padding: var(--ui-padding);
    margin-top: 5vh;
    margin-bottom: 2vh;
    gap: var(--thread-gap);
}

form.input {
    width: 100%;
    display: flex;
    gap: var(--ui-padding);
    padding-top: var(--ui-padding);
    align-items: flex-end;
    border-top: 1px solid var(--ui-color);
    transition: border-top 1s;
}

form.input div.input-border {
    height: var(--input-area-height);
    /* height: 100%; */
    overflow-y: hidden;
    overflow-x: hidden;
    flex-grow: 1;
    background-color: none;
    border: none;
    outline: none;
    transition: opacity 1s;
    display: flex;
    box-sizing: border-box;
}

form.input div.input-border textarea {
    font-family: var(--font);
    font-size: calc(var(--input-text-size) / 1.5);
    white-space: pre-wrap;
    background: none;
    border: none;
    padding: 0 var(--ui-padding);

    font-size: var(--input-text-size);
    font-family: var(--input-text-font);
    outline: none;
    resize: none !important;
    scrollbar-width: thin;
    color: var(--ui-color);
    transition: color 1s;
    height: 100%;
    width: calc(100% - var(--submit-button-size));
}

form.input div.input-border textarea::placeholder {
    color: var(--ui-color);
    transition: color 1s;
    opacity: 0.5;
}

form.input div.input-border textarea::selection {
    background: var(--highlight-color);
}

form.input div.input-border textarea::-webkit-scrollbar {
    width: 0.75vw;
}

form.input div.input-border textarea::-webkit-scrollbar-thumb {
    background-color: var(--ui-color);
    transition: background-color 1s;
    border-radius: 50px;
}

form.input div.input-border textarea::-webkit-scrollbar-track {
    background-color: none;
}



form.input input[disabled] {
    opacity: 0.5;
}

form.input button {
    position: absolute;
    bottom: 0;
    right: 0;
    height: var(--submit-button-size);
    width: var(--submit-button-size);
    min-width: var(--submit-button-size);
    border: none;
    cursor: pointer;
    transition: opacity 1s;
    background: none;
    padding: 0;
}

form.input button img {
    width: 100%;
}

form.input button[disabled] {
    pointer-events: none;
    cursor: not-allowed;
}

span.time {
    display: none;
    white-space: nowrap;
    opacity: 0.5;
    font-size: 0.75em;
}

li:last-child span.time {
    display: inline;
}
</style>