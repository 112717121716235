<template>
    <span>
        <router-link :style="sector.header_style" :to="`/c/${sector.slug}`">{{ sector.name }}</router-link>
    </span>
</template>

<script>

export default {
    props: ['sector'],
}
</script>

<style scoped>
span {
    font-size: 4vmax;
}

span a,
span a:visited {
    color: black;
    text-decoration: none;
}
</style>