<template>
    <div ref="element" :class="['signup', 'row', { show }, { hidden }]">
        <div class="col-5 col-2-xs description">
            <h5>
                Receive inspirations, invitations and tools from FutureMakers.
            </h5>
        </div>
        <a class="exit col-1 hide-on-desktop" @click="hide">
            <svg width="86" height="53" viewBox="0 0 86 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line y1="-0.5" x2="99.1262" y2="-0.5" transform="matrix(-0.857493 0.514496 -0.857493 -0.514496 85 1)"
                    stroke="black" />
                <line y1="-0.5" x2="99.1262" y2="-0.5" transform="matrix(-0.857493 -0.514496 -0.857493 0.514496 85 52)"
                    stroke="black" />
            </svg>
        </a>
        <NewsletterSignup class="signup-form col-6 col-3-xs" />
        <a class="exit col-1 hide-on-mobile" @click="hide">
            <svg width="86" height="53" viewBox="0 0 86 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line y1="-0.5" x2="99.1262" y2="-0.5" transform="matrix(-0.857493 0.514496 -0.857493 -0.514496 85 1)"
                    stroke="black" />
                <line y1="-0.5" x2="99.1262" y2="-0.5" transform="matrix(-0.857493 -0.514496 -0.857493 0.514496 85 52)"
                    stroke="black" />
            </svg>
        </a>
    </div>
</template>

<script>
import NewsletterSignup from './NewsletterSignup.vue';

export default {
    props: {
        automatic: { default: true },
        hideAfterTransition: { default: true }
    },
    data() {
        return {
            show: false,
            hidden: false,
        }
    },
    methods: {
        reveal() {
            this.hidden = false
            this.show = true
        },
        hide() {
            this.show = false
            if(this.hideAfterTransition)
                this.$refs.element.addEventListener('transitionend', () => {
                    this.hidden = true
                }, { once: true })
        }
    },
    components: { NewsletterSignup },
    mounted() {
        if(this.automatic)
            setTimeout(() => this.reveal(), 1000)
    }
}
</script>

<style scoped>
div.signup {
    --popup-height: calc(var(--gutter) * 4);
    padding: var(--gutter) 0 var(--gutter) var(--gutter);
    border-top: 1px solid black;
    /* border-bottom: 1px solid black; */
    position: fixed;
    z-index: 50;
    background-color: white;
    width: var(--app-width);
    top: 100%;
    height: var(--popup-height);
    transition: top 1s;
}

@media (max-width: 1280px) {
    div.signup {
        --popup-height: 20vh;
    }
}

@media (max-width: 960px) {
    div.signup {
        --popup-height: calc(var(--column) * 2);
    }
}

div.signup.show {
    top: calc(100% - var(--popup-height));
}

div.signup.hidden {
    display: none;
}

div.signup-form {
    display: flex;
}

a.exit {
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
}

@media (max-width: 1280px) {
    a.exit {
        height: auto;
    }
}

div.description {
    display: flex;
    align-items: center;
}

svg {
    width: 100%;
}

.signup :deep(form) {
    justify-content: flex-start;
}

.signup :deep(form) input[type=email] {
    width: calc(var(--column) * 4 + var(--gutter) * 3) !important;
}

.signup :deep(form) input[type=submit] {
    width: calc(var(--column) * 2 + var(--gutter) * 1) !important;
}

@media (max-width: 1280px) {
    .signup :deep(form) {
        flex-direction: column;
        /* background-color: orange; */
    }

    .signup :deep(form) input[type=email] {
        width: 100% !important;
    }

    .signup :deep(form) input[type=submit] {
        width: 100% !important;
    }
}

@media (max-width: 960px) {
    .signup :deep(form) input[type=submit] {
        width: var(--column) !important;
    }
}
</style>