<template>
    <header :style="{ '--color': color, '--background': backgroundStyle }" :class="[{ fixed }, 'row', 'full']">
        <div class="col-3 menu-logo">
            <router-link class="" to="/">The Accelerator</router-link>
        </div>
        <menu class="col-9">
            <li><router-link to="/categories">
                    <h5>Find Your Action</h5>
                </router-link></li>
            <li><router-link to="/dreamcube">
                    <h5>DreamCube</h5>
                </router-link></li>
            <li><router-link to="/futuremakers">
                    <h5>FutureMakers</h5>
                </router-link></li>
            <li><router-link to="/about">
                    <h5>About</h5>
                </router-link></li>
            <li><router-link to="/partnerwithus">
                    <h5>Partner With Us</h5>
                </router-link></li>
        </menu>
    </header>
    <header :style="{ '--color': color, '--background': backgroundStyle }" class="mobile">
        <router-link class="menu-logo" to="/">TA</router-link>
        <a class="menu-toggle" @click="toggle">
            <svg v-if="popup" width="70" height="35" viewBox="0 0 70 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1618_3314)">
                    <path d="M69.5 0.5L0.400024 34.6" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M69.5 34.5L0.400024 0.400024" stroke="black" stroke-linecap="round"
                        stroke-linejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_1618_3314">
                        <rect width="70" height="35" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            <svg v-else width="70" height="35" viewBox="0 0 70 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1618_3309)">
                    <path d="M0 0.5H70" stroke="black" />
                    <path d="M0 17.5H70" stroke="black" />
                    <path d="M0 34.5H70" stroke="black" />
                </g>
                <defs>
                    <clipPath id="clip0_1618_3309">
                        <rect width="70" height="35" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </a>
    </header>
    <div class="spacer"></div>
    <div :class="['popup-menu', { visible: popup }]">
        <ul>
            <li><router-link to="/categories">Find Your Action</router-link></li>
            <li><router-link to="/dreamcube">DreamCube</router-link></li>
            <li><router-link to="/futuremakers">FutureMakers</router-link></li>
            <li><router-link to="/about">About</router-link></li>
            <li><router-link to="/partnerwithus">Partner With Us</router-link></li>
        </ul>
        <p>
            Inspirations, invitations and resources from FutureMakers. <button class="body-text text" @click="revealNewsletter">Sign up here</button>.
        </p>
    </div>
    <NewsletterPopup ref="newsletter" :automatic="showSignup" :hide-after-transition="false" />
</template>

<script>
import NewsletterPopup from './NewsletterPopup.vue';

export default {
    components: { NewsletterPopup },
    data() {
        return {
            popup: false
        }
    },
    props: {
        fixed: { default: true },
        color: { default: "black" },
        background: { default: "white" },
        showSignup: { default: false }
    },
    methods: {
        toggle() {
            this.popup = !this.popup;
            this.hideNewsletter()
        },
        revealNewsletter() {
            this.popup = false
            this.$refs.newsletter.reveal()
        },
        hideNewsletter() {
            this.$refs.newsletter.hide()
        }
    },
    computed: {
        backgroundStyle() {
            return this.background === 'wormhole'
                ? 'url("/images/wormhole-header.png") center top / cover'
                : this.background
        }
    }
}

</script>

<style scoped>
header {
    width: var(--app-width);
    padding-top: var(--gutter);
    padding-bottom: var(--gutter);
    box-sizing: border-box;
    top: 0;
    background: var(--background);
    z-index: 2;
    color: var(--color);
    transition: color 1s;
    border-bottom: 1px solid var(--color);
    height: var(--header-height);
}

header.fixed {
    position: fixed;
}

menu {
    height: var(--header-content-height);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    transition: height 0.5s;
    z-index: 20;
}

menu li {
    white-space: nowrap;
}

header.mobile {
    font-family: 'PP Editorial Old';
    /* font-size: var(--header-menu-font-size); */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: var(--header-gap);
    position: fixed;
    height: var(--header-height);
}

header.mobile,
header.mobile * {
    z-index: 20;
}

header.mobile a,
header.mobile a:visited {
    color: black;
    text-decoration: none;
}

header.mobile a.menu-toggle {
    cursor: pointer;
}

header.mobile svg {
    height: var(--header-logo-font-size);
    width: var(--header-logo-font-size);
}

header a {
    color: var(--link-color);
    text-decoration: none;
    transition: color 0.5s;
}

header a:hover {
    color: var(--link-hover-color);
    text-decoration: none;
}

div.popup-menu {
    width: 100vw;
    height: 100%;
    position: fixed;
    background-color: white;
    z-index: 15;
    top: -100%;
    left: 0;
    transition: top 0.25s ease-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--gutter);
    box-sizing: border-box;
}

div.popup-menu.visible {
    top: 0;
}

div.popup-menu * {
    z-index: 16;
}

div.popup-menu ul {
    display: flex;
    flex-direction: column;
    margin-top: calc(var(--header-height) + var(--gutter));
    box-sizing: border-box;
    gap: var(--gutter);
    font-family: 'Px Grotesk';
    color: black;
}

div.popup-menu ul a {
    color: black;
    text-decoration: none;
    font-size: 9vw;
    letter-spacing: -0.05rem;
}

div.popup-menu menu li {
    color: black;
}

div.spacer {
    height: var(--header-height);
}

button.text {
    border: none;
    background-color: transparent;
    display: inline;
    text-decoration: underline;
    padding: 0;
}

@media (max-width: 1280px) {
    /* header {
        height: var(--header-height) !important;
        background-color: orange
    } */

    header.full {
        display: none;
    }

    .menu-logo {
        font-size: var(--header-logo-font-size) !important;
        line-height: 0.95em !important;
        letter-spacing: 0 !important;
    }
    
    .menu-logo,
    .menu-logo a {
        color: black !important;
    }

    .body-text {
        color: black !important;
    }
}

@media (min-width: 1280px) {
    div.popup-menu {
        display: none;
    }

    header.mobile {
        display: none;
    }
}
</style>