<template>
    <div :class="['thread', source, { pending, transition }]" :style="{ '--ui-color': uiColor }">
        <ul>
            <li v-if="typing">
                <beat-loader :color="'black'" />
            </li>
            <li v-for="message in messages" v-else>
                <span class="body" v-if="message.body" v-html="message.body"></span>
                <img :src="assetURL(message.image)" v-if="message.image">
                <span class="buttons" v-if="message.buttons">
                    <button :class="{ selected: buttonLabel === button.label }" @click="buttonPress(button)"
                        v-for="button in message.buttons">
                        {{ button.label }}
                    </button>
                </span>
            </li>
            <li v-if="buttonDetail">
                <span class="detail">
                    {{ buttonDetail }}
                    <span class="buttons">
                        <button @click="detailConfirm()">Pursue This</button>
                        <button @click="detailReject()">Try Others</button>
                    </span>
                </span>
            </li>
        </ul>
        <div ref="since" :class="['since', source, { pending }]" v-if="!typing && !pending && !transition">
            {{ sinceLabel(source, timestamp) }}
        </div>
    </div>
</template>

<script>
import { DateTime } from 'luxon'
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
import { assetURL } from '../mixins.js'

export default {
    data() {
        return {
            timeRefreshInterval: null,
            buttonLabel: null,
            buttonDetail: null
        }
    },
    components: { BeatLoader },
    props: {
        typing: Boolean,
        pending: Boolean,
        source: String,
        timestamp: Number,
        messages: Array,
        uiColor: String
    },
    computed: {
        transition() {
            return this.source === 'transition'
        }
    },
    methods: {
        assetURL,
        detailConfirm() {
            this.$emit('choice', this.buttonLabel)
        },
        detailReject() {
            this.buttonDetail = null
            this.buttonLabel = null
        },
        buttonPress(button) {
            this.buttonLabel = button.label
            if (button.detail) {
                this.$emit('should-scroll')
                this.buttonDetail = button.detail
            } else {
                this.$emit('choice', button.label)
            }
        },
        sinceLabel(source, timestamp) {
            let author = ""
            if (source === "bot") {
                author = "FutureBot"
            } else if (source === "user") {
                author = "You"
            } else if (source === "future") {
                author = "FutureYou"
            }
            const ago = DateTime.fromMillis(parseInt(timestamp)).toRelative()
            const since = ago === '0 seconds ago' ? 'Now' : ago
            return `${author} • ${since}`
        },
    },
    mounted() {
        this.timeRefreshInterval = setInterval(() => {
            const sinceElement = this.$refs?.since
            if (sinceElement) {
                const sinceLabel = this.sinceLabel(this.source, this.timestamp)
                sinceElement.textContent = sinceLabel
            }
        }, 30 * 1000);

    },
    beforeUnmount() {
        if (this.timeRefreshInterval)
            clearInterval(this.timeRefreshInterval)
    }
}
</script>

<style scoped>
div.thread {
    --ui-color: black;
    --bot-font: 'IBM Plex Mono';
    --user-font: 'Px Grotesk';
    --timestamp-font: 'Px Grotesk';
    --future-font: 'Px Grotesk';
    --chat-text-size: 2vh;
    --chat-text-color: black;
    --radius-big: 2vh;
    --radius-small: 0.25vh;
    --chat-margin: 1vw;
    --button-background: white;
    --button-border: 1px solid black;
    --button-padding: 1vh;
    --button-margin: 1vh;
}


div.thread {
    display: flex;
    flex-direction: column;
    gap: 1vh;
    color: var(--chat-text-color);
    width: 100%;
}

div.thread.bot,
div.thread.transition,
div.thread.future {
    align-items: flex-start;
}

div.thread.user {
    align-items: flex-end;
}

div.thread ul {
    display: flex;
    flex-direction: column;
    gap: var(--chat-margin);
    max-width: 60vw;
    box-sizing: border-box;
}

@keyframes enter-left {
    0% {
        transform: translate(-150%);
    }

    100% {
        transform: translate(0);
    }
}

@keyframes enter-right {
    0% {
        transform: translate(150%);
    }

    100% {
        transform: translate(0);
    }
}

div.thread.user ul li {
    align-items: flex-end;
    align-self: flex-end;
    animation: enter-right 0.2s ease-out;
    animation-fill-mode: both;
}

div.thread.transition ul li,
div.thread.future ul li,
div.thread.bot ul li {
    align-items: flex-start;
    align-self: flex-start;
    animation: enter-left 0.2s ease-out;
    animation-fill-mode: both;
}

div.thread ul li {
    animation-delay: 0s !important;
}

div.thread ul li+li {
    animation-delay: 0.1s !important;
}

div.thread ul li+li+li {
    animation-delay: 0.2s !important;
}

div.thread ul li+li+li+li {
    animation-delay: 0.3s !important;
}

div.thread ul li+li+li+li+li {
    animation-delay: 0.4s !important;
}

div.thread ul li+li+li+li+li+li {
    animation-delay: 0.5s !important;
}

div.thread ul li {
    width: 100%;
    border: 1px solid black;
    border-radius: var(--radius-big);
    padding: var(--radius-big);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--chat-margin);
    list-style: none;
    background: white;
    box-sizing: border-box;
    transition: border-radius 0.5s;
}

div.thread.pending ul li {
    border: 1px dashed black;
}

div.thread.future ul li:not(:only-of-type),
div.thread.bot ul li:not(:only-of-type) {
    border-radius: var(--radius-small) var(--radius-small) var(--radius-small) var(--radius-small);
}

div.thread.future ul li:first-of-type,
div.thread.bot ul li:first-of-type {
    border-radius: var(--radius-big) var(--radius-big) var(--radius-small) var(--radius-small);
}

div.thread.future ul li:last-of-type,
div.thread.bot ul li:last-of-type {
    border-radius: var(--radius-small) var(--radius-small) var(--radius-big) var(--radius-small);
}

div.thread.user ul li {
    border-radius: var(--radius-big) var(--radius-big) var(--radius-small) var(--radius-big);
}

div.thread.future ul li:only-of-type,
div.thread.bot ul li:only-of-type {
    border-radius: var(--radius-big) var(--radius-big) var(--radius-big) var(--radius-small);
}

div.thread.pending ul li {
    opacity: 0.5;
}

div.thread ul li {
    line-height: calc(var(--chat-text-size) * 1.25);
    font-size: var(--chat-text-size);
}

div.thread ul li :deep(button) {
    background: var(--button-background);
    color: var(--ui-color);
    border: var(--button-border);
    border-radius: var(--radius-big);
    font-size: inherit;
    padding: var(--button-padding);
    margin-top: var(--button-margin);
    pointer-events: none;
    opacity: 0.5;
    white-space: nowrap;
}

div.thread ul li :deep(button.selected) {
    color: var(--button-background);
    background-color: var(--ui-color);
}

div.thread:last-of-type ul li:last-of-type :deep(button) {
    cursor: pointer;
    pointer-events: all;
    opacity: 1;
}

div.thread ul li :deep(a) {
    color: var(--user-text-color);
    font-weight: bold;
}

div.thread.user ul li {
    color: var(--user-text-color);
    /* text-align: right; */
}

div.thread.bot ul li {
    font-family: var(--bot-font);
}

div.thread.future ul li {
    font-family: var(--future-font);
}

div.thread.user ul li {
    font-family: var(--user-font);
}

div.thread ul li img {
    max-width: 100%;
}

div.thread.user ul :deep(button) {
    border-radius: var(--chat-button-border-radius);
    border: 1px solid black;
    background-color: var(--prompt-button-background);
    font-family: 'Noto Sans';
    padding: 0.5ch 1ch;
    margin-top: 1ch;
    cursor: pointer;
    opacity: 0.5;
    pointer-events: none;
    font-size: inherit;
}

div.thread.user ul :deep(button) {
    pointer-events: all;
    opacity: 1;
}


div.thread.transition ul li {
    font-family: var(--bot-font);
}

div.thread.transition ul li span {
    display: none;
}

div.thread.transition ul li {
    content: "Connection to future established!";
}

div.thread.transition:last-of-type {
    /* border-radius: var(--radius-big); */
    overflow: hidden;
}

@keyframes transition-messages {
    0% {
        content: "connecting to future...";
    }

    10% {
        content: "stand by...";
    }

    20% {
        content: "processing inputs...";
    }

    30% {
        content: "hold on...";
    }

    40% {
        content: "processing outputs...";
    }

    50% {
        content: "just a second...";
    }

    60% {
        content: "reticulating splines...";
    }

    70% {
        content: "patching vulnerabilities...";
    }

    80% {
        content: "entertaining alternatives...";
    }

    90% {
        content: "any moment now...";
    }
}

div.thread.transition:last-of-type ul li::after {
    color: var(--chat-text-color);
    content: "establishing connection...";
    animation: transition-messages 20s linear infinite;
}

div.thread.transition:not(:last-of-type) {
    display: none;
}

div.since {
    font-family: var(--timestamp-font);
    font-size: var(--chat-text-size);
    color: var(--ui-color);
    transition: color 1s;
}

div.since.future,
div.since.bot {
    align-self: flex-start;
}

span.buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

span.buttons button {
    margin-right: 1ch;
    font-family: 'Px Grotesk';
}
</style>