
/**
 * Base URL of the socket server, without /ws/ (that gets added locally)
 * 
 * Value comes from frontend/.env.development or frontend/.env.production
 */
export const SOCKET_SERVER = import.meta.env.VITE_SOCKET_SERVER

/**
 * Base URL of the CRM/Directus API
 * 
 * Value comes from frontend/.env.development or frontend/.env.production
 */
export const API_SERVER = import.meta.env.VITE_API_SERVER

/**
 * Site key of the cloudflare turnstile validation
 * 
 * Value comes from frontend/.env.development or frontend/.env.production
 */
export const CLOUDFLARE_TURNSTILE_SITE = import.meta.env.VITE_CLOUDFLARE_TURNSTILE_SITE