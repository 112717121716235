import { createApp } from 'vue';
import App from './components/App.vue';
import router from './router'
import VueSocialSharing from 'vue-social-sharing'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { faXTwitter, faFacebook, faReddit, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faCommentSms, faLink } from '@fortawesome/free-solid-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(faXTwitter, faFacebook, faReddit, faLinkedin, faEnvelope, faCommentSms, faLink)

import 'reset-css';

console.log('built from', VERSION_INFO)

const app = createApp(App)
app.use(VueSocialSharing)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router)
app.mount('#app');