<template>
    <header :style="{ '--color': color, '--background': background }">
        <router-link to="/">The Accelerator</router-link>
    </header>
</template>

<script>
export default {
    props: {
        color: { default: "black" },
        background: { default: "none" },
    }
}
</script>

<style scoped>
header {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color);
    font-family: 'PP Editorial Old';
    font-weight: 400;
    height: 5vh;
    font-size: 4vh;
    border-bottom: 1px solid var(--color);
    background: var(--background);
    /* margin: 0 1vh; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

a {
    color: var(--color);
    text-decoration: none;
}

a:visited {
    color: var(--color);
}

</style>