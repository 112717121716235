import * as env from "./env.js"

export function assetURL(id) {
    return `${env.API_SERVER}/assets/${id}`
}

export function shareNetworks() {
    return [
        { name: 'twitter', icon: 'fa-brands fa-x-twitter' },
        { name: 'facebook', icon: 'fa-brands fa-facebook' },
        { name: 'reddit', icon: 'fa-brands fa-reddit' },
        { name: 'sms', icon: 'fa-comment-sms' },
        { name: 'email', icon: 'fa-envelope' },
    ]
}

export function copyTextToClipboard(text, event) {
    if (event) event.preventDefault()
    navigator.clipboard.writeText(text)
}

export function caseStudyURL(sectorSlug, caseStudySlug, absolute=false) {
    return `${absolute ? 'https://theaccelerator.studio' : ''}/c/${sectorSlug}/${caseStudySlug}`
}