<template>
    <HeaderSite />
    <PageLoading v-if="!content" />
    <main v-if="content">
        <h2>Mission</h2>
        <img class="header" :src="assetURL(content.header)">

        <section class="mission row">
            <h3 class="col-8 col-3-xs">
                {{ content.mission }}
            </h3>
        </section>

        <section class="why">
            <h2>Our Why</h2>
            <div class="row">
                <h4 class="col-8 col-3-xs">
                    {{ content.why_header }}
                </h4>
            </div>
            <div class="row">
                <h5 class="column col-6 col-3-xs" v-html="content.why_left"> </h5>
                <h5 class="column col-6 col-3-xs" v-html="content.why_right"> </h5>
            </div>
            <div class="why-footer row">
                <div class="column col-4 col-3-xs" v-html="content.why_footer_left"> </div>
                <div class="column col-4 col-3-xs" v-html="content.why_footer_middle"> </div>
                <div class="column col-4 col-3-xs" v-html="content.why_footer_right"> </div>
            </div>

        </section>
        <section class="about">
            <h2>About</h2>
            <div class="row">
                <h5 class="column col-6 col-3-xs" v-html="content.about_left"> </h5>
                <h5 class="column col-6 col-3-xs" v-html="content.about_right"> </h5>
            </div>
        </section>
    </main>
    <Footer />
</template>

<script>
import PageLoading from './PageLoading.vue'
import HeaderSite from './HeaderSite.vue'
import Footer from './Footer.vue'
import api from '@accelerator/common/crm-api.js'
import * as env from "../env.js"
import { assetURL } from "../mixins.js"

const apiServerURL = new URL(env.API_SERVER, document.baseURI).toString()
const { about } = api(apiServerURL)


export default {
    components: { HeaderSite, Footer, PageLoading },
    data() { return { content: null } },
    methods: {
        assetURL
    },
    async mounted() {
        this.content = await about.get()
    }
}
</script>

<style scoped>
@import url("./content.css");

main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h2 {
    padding: var(--gutter);
}

section.why h2,
section.about h2 {
    padding-bottom: var(--column);
}

section.why .row {
    margin-bottom: var(--gutter);
}

section {
    box-sizing: border-box;
    font-family: 'Px Grotesk';
    font-size: clamp(18pt, 1vw, 24pt);
}

section.mission {
    padding-top: var(--gutter);
    padding-bottom: var(--column);
}

section.mission div {
    width: 60%;
}

section.mission span {
    font-size: 0.5em;
}

section.why,
section.about {
    border-top: 1px solid black;
}

section.why div.header {
    font-family: 'PP Editorial Old';
    font-size: var(--homepage-section-font-size);
    padding: var(--sector-body-padding);
    width: 60%;
}

section header {
    text-align: center;
    font-size: var(--homepage-section-font-size);
    padding-bottom: var(--about-heading-padding);
}

img.header {
    display: block;
    min-width: 100vw;
}

@media (max-width: 960px) {
    section.mission {
        padding: var(--gutter2) var(--gutter) var(--column) var(--gutter);
    }

    section.why h2,
    section.about h2  {
        line-height: 1em !important;
        padding-bottom: var(--gutter2);
    }
    
    section.why,
    section.about {
        padding: 0;
    }

    section header {
        /* padding-bottom: 5vh; */
    }

    .columns {
    }
}
</style>