<template>
    <HeaderSite />
    <PageLoading v-if="!content" />
    <main v-if="content">
        <header>
            <h2>Partner With Us</h2>
            <img :src="assetURL(content.header_image)">
            <div class="row">
                <h3 class="col-8 col-3-xs">
                    {{ content.header_text }}
                </h3>
            </div>
        </header>
        <section class="body hide-on-mobile">
            <div class="row" v-for="(body, i) in content.body">
                <h3 v-if="i % 2 == 0" class="left off-1 col-3 off-0-xs column">{{ body.name }}</h3>
                <p v-if="i % 2 == 0" class="left column col-7 col-3-xs off-0-xs" v-html="body.content"> </p>
                <p v-if="i % 2 == 1" class="right off-1 col-7 col-3-xs off-0-xs column" v-html="body.content"></p>
                <h3 v-if="i % 2 == 1" class="right column col-3">{{ body.name }}</h3>
            </div>
        </section>
        <section class="body hide-on-desktop">
            <div class="row wide" v-for="(body, i) in content.body">
                <h3 class="left off-1 col-3 off-0-xs column">{{ body.name }}</h3>
                <p class="left column col-7 col-3-xs off-0-xs" v-html="body.content"> </p>
            </div>
        </section>
        <div class="footer row">
            <div class="col-12 col-3-xs" v-html="content.footer"></div>
        </div>
    </main>
    <Footer />
</template>

<script>
import PageLoading from './PageLoading.vue'
import HeaderSite from './HeaderSite.vue'
import Footer from './Footer.vue'
import api from '@accelerator/common/crm-api.js'
import * as env from "../env.js"
import { assetURL } from '../mixins'

const apiServerURL = new URL(env.API_SERVER, document.baseURI).toString()
const { partnerwithus } = api(apiServerURL)

export default {
    data() { return { content: null } },
    components: { HeaderSite, Footer, PageLoading },
    methods: { assetURL },
    async mounted() {
        this.content = await partnerwithus.get()
        console.log(this.content)
    }
}
</script>

<style scoped>
@import url("./content.css");

main {
    min-height: calc(100vh - var(--header-height));
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

h2 {
    padding: var(--gutter);
}

header {
    margin-bottom: var(--column);
}

header img {
    width: 100vw;
    height: calc(100vh - var(--header-height) - 7vw);
    object-fit: cover;
    margin-bottom: var(--gutter);
}

section.body {
    --image-height: 50vh;
    width: 100%;
}

section.body .row-10 {
    padding-left: 0;
    width: 100%;
}

section.body h3.left {
    text-align: right;
}

section.body h3 {
    margin-top: calc(var(--image-height) + var(--gutter));
}

section.body .column :deep(img:first-of-type) {
    height: var(--image-height);
    min-width: 100%;
    object-fit: cover;
}

.footer {
    border-top: 1px solid black;
    box-sizing: border-box;
    padding-top: var(--gutter);
    padding-right: 0 !important;
}

.footer :deep(h1) {
    padding-bottom: var(--gutter);
    width: 60%;
}

.footer :deep(p) {
    width: 100%;
}

@media (max-width: 960px) {
    .row {
        /* padding-right: var(--gutter); */
    }
    header {
        margin-bottom: var(--gutter2);
    }
    header img {
        margin-bottom: var(--gutter2);
    }

    header section {
        width: 100vw;
        padding: 5vh 1vw;
    }

    section.body h3.left {
        margin-top: 0;
        padding-bottom: 0;
        text-align: left;
        padding-right: var(--gutter);
        padding-left: var(--gutter);
    }

    section.body p.left >:deep(*) {
        padding-right: var(--gutter);
        padding-left: var(--gutter);
    }

    section.body p.left >:deep(*:first-child) {
        padding-right: 0;
        padding-left: 0;
    }

    section.body p.left,
    section.body p.left >:deep(*:last-child) {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    section.body div.column:first-child {
        text-align: left;
        padding: var(--sector-body-padding);
    }

    section.body >.row {
        flex-direction: column;
        padding-bottom: var(--gutter3);
    }

    section.body >.row:nth-child(2n) {
        flex-direction: column;
    }

    .footer {
        padding-top: var(--gutter2);
        padding-bottom: var(--gutter3);
    }

    .footer div >:deep(*) {
        width: 100%;
    }

    .footer :deep(img) {
        margin-top: var(--gutter);
        max-width: 100%;
    }
}
</style>