<template>
    <img :style="styleOverride" class="cta base" src="/images/find-your-action.svg" />
    <img :style="styleOverride" class="cta rollover" src="/images/find-your-action-rollover.svg" />
</template>

<script>
export default {
    props: ["styleOverride"]
}
</script>

<style scoped>
img.cta {
    display: block;
    position: absolute;
    z-index: 1;
    transition: opacity 0.5s;
    max-width: 45vw;
    max-height: calc(var(--footer-height) - var(--sector-body-padding) * 2);
    bottom: var(--gutter);
    right: var(--gutter);
}

img.cta.rollover {
    opacity: 0;
}

img.cta.base:hover {
    opacity: 0;
}

img.cta.rollover:hover {
    opacity: 1;
}
</style>