<template>
    <ChatHeader :color="uiColor" />
    <ChatUI @message="onmessage" @typing="ontyping" :id="id" :messages="messages" :pending-messages="pending"
        :typing="typing" :connected="enabled" :ui-color="uiColor" :finished="finished" />
    <div :class="['wormhole', { visible: state === 'transition' }]">
        <video src="/videos/wormhole.mp4" autoplay loop muted playsinline></video>
    </div>
    <div :class="['gradient', { visible: state === 'future' }]"> </div>
</template>

<script>
import ChatHeader from './ChatHeader.vue';
import ChatUI from './ChatUI.vue';
import * as socket from '../socket.js'
import * as env from '../env.js'

const ws = socket.connect(env.SOCKET_SERVER)

export default {
    props: ['id'],
    components: { ChatUI, ChatHeader },
    data() {
        return {
            messages: [],
            pending: [],
            typing: false,
            enabled: true,
            finished: false
        }
    },
    computed: {
        uiColor() {
            return this.state === 'transition' ? 'white' : 'black'
        },
        state() {
            const nonUserMessages = this.messages.filter(m => m.source !== 'user')
            return nonUserMessages[nonUserMessages.length - 1]?.source || 'bot'
        }
    },
    methods: {
        setState(state) {
            // this.state = state
        },
        ontyping(localTyping) {
            ws.chat.typing.emit(localTyping)
        },
        onmessage(body) {
            const message = { body, source: 'user' }
            this.pending = [...this.pending, message]
            ws.chat.message.emit(message)
        }
    },
    mounted() {
        ws.connect.on(() => {
            this.enabled = true
            ws.chat.start.emit(this.id, this.messages.length)
        })
        ws.disconnect.on(() => this.enabled = false)
        ws.chat.notFound.on(() => {
            this.$router.push('/')
        })
        ws.chat.finished.on(() => {
            this.finished = true
        })
        ws.chat.messages.on(({ messages }) => {
            this.pending = []
            for (const message of messages) {
                this.messages = [...this.messages, message]
            }
        })
        ws.chat.typing.on(remoteTyping => {
            this.typing = remoteTyping
        })
    }
}
</script>

<style scoped>
div.gradient,
div.wormhole {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -20;
    opacity: 0;
    transition: opacity 1s;
    overflow: hidden;
}

div.gradient.visible,
div.wormhole.visible {
    opacity: 1;
}

div.wormhole video {
    min-width: 100vw;
    min-height: 100vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@keyframes gradient-scroll {
    0% {
        background-position-y: 0;
    }

    100% {
        background-position-y: 100vh;
    }
}

div.gradient {
    animation: gradient-scroll 30s linear infinite;
    background-image: linear-gradient(180deg,
            rgba(229, 229, 229, 1) 0%,
            rgba(148, 43, 166, 0.4318321078431373) 25%,
            rgba(184, 66, 66, 1) 50%,
            rgba(148, 43, 166, 0.4318321078431373) 75%,
            rgba(229, 229, 229, 1) 100%);
}
</style>