const now = () => new Date().toISOString().replace(/\..*$/, "")

function debug(ns = 'debug') {
    const f = (...args) => console.log(now(), ns, ...args)
    const handler = {
        get(obj, prop) {
            if (!(prop in obj)) {
                obj[prop] = debug(`${ns}:${prop}`)
            }
            return obj[prop]
        }
    }
    return new Proxy(f, handler)
}

export default debug
