<template>
    <HeaderSite background="white" />
    <PageLoading v-if="!sectors" />
    <div v-if="sectors" class="header row">
        <h1 class="col-11 col-3-xs">Find Your Action.</h1>
        <h4 class="col-6 col-3-xs">All our categories are communities: where you work, where your kids go to school,
            where you live, so the impact is social, building civic engagement while normalizing climate action. Explore
            below or use our <a href="/chat/new">AI-Powered Chatbot</a> to find where you can make the most impact
            quickly.</h4>
        <a href="/chat/new" class="cta-container hide-on-mobile">
            <CallToActionPerson />
        </a>
    </div>
    <div v-if="sectors" class="filter">
        <label v-for="category in categories">
            <input type="checkbox" v-model="filters[category]">
            <span class="h4">
                <img class="plus" src="/images/plus.svg">
                <img class="check" src="/images/check.svg">
                {{ category }}
            </span>
        </label>
    </div>
    <div class="sectors" v-if="sectors">
        <div class="sector" v-for="sector in filteredSectors" :key="sector.id">
            <SectorHeader :sector="sector" />
            <router-link :to="`/c/${sector.slug}`">
                <img v-if="sector.image" :src="assetURL(sector.image)">
                <img v-else src="https://placehold.co/600x400">
            </router-link>
        </div>
    </div>
    <Footer />
</template>

<script>
import PageLoading from './PageLoading.vue';
import SectorHeader from './SectorHeader.vue';
import HeaderSite from './HeaderSite.vue';
import Footer from './Footer.vue';
import CallToActionPerson from './CallToActionPerson.vue';
import api from '@accelerator/common/crm-api.js'
import * as env from "../env.js"
import { assetURL } from "../mixins.js"

const apiServerURL = new URL(env.API_SERVER, document.baseURI).toString()

const { sector } = api(apiServerURL)

export default {
    data() {
        return {
            sectors: null,
            filters: {}
        }
    },
    computed: {
        isFilterActive() {
            for (const v of Object.values(this.filters))
                if (v) return true
            return false
        },
        filteredSectors() {
            return this.sectors?.filter(s => this.matchesActiveFilter(s.category))
        },
        categories() {
            return [...new Set(this.sectors?.map(s => s.category))]
        }
    },
    components: { HeaderSite, Footer, SectorHeader, CallToActionPerson, PageLoading },
    methods: {
        assetURL,
        matchesActiveFilter(category) {
            if (!this.isFilterActive)
                return true
            return this.filters[category]
        }
    },
    async mounted() {
        this.sectors = await sector.getAll()
    }
}
</script>

<style scoped>
.cta-container :deep(img) {
    bottom: 10% !important;
    height: 70% !important;
    max-height: none !important;
}

div.header {
    width: 100%;
    box-sizing: border-box;
    padding-top: var(--gutter);
    position: relative;
    gap: 0;
}

div.header h1 {
    margin-bottom: var(--column);
}

@media (max-width: 960px) {
    div.header h1 {
        margin-bottom: var(--gutter);
    }
}


div.filter {
    font-family: 'Px Grotesk';
    font-size: 2vmax;
    display: flex;
    flex-wrap: wrap;
    /* gap: 1.2vmax; */
    justify-content: space-between;
    margin: var(--column) var(--gutter);
}

@media (max-width: 960px) {
    div.filter {
        margin: var(--gutter2) var(--gutter);
        justify-content: flex-start;
        gap: var(--gutter);
    }
}

div.filter input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
}

div.filter label span {
    font-size: 2.5vmax;
    border: 1px solid black;
    border-radius: 10em;
    padding: 0.125vmax 1.25vmax 0.125vmax 0.75vmax;
    user-select: none;
    background-color: white;
    color: black;
    transition: color 0.5s, background-color 0.5s;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5vmax;
    text-transform: capitalize;
}

div.filter label span img {
    height: 1.4vmax;
    width: 1.4vmax;
}

div.filter label input:checked+span {
    background-color: black;
    color: white;
}

div.filter label span img.plus {
    display: inline;
}

div.filter label span img.check {
    display: none;
}

div.filter label input:checked+span img.check {
    display: inline;
}

div.filter label input:checked+span img.plus {
    display: none;
}

div.sectors {
    --gap: var(--gutter);
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    gap: var(--gap);
    margin-bottom: var(--gap);
    justify-content: space-between;
}

div.sectors div.sector {
    width: calc(50% - var(--gap)/2);
    aspect-ratio: 1/1;
    border-top: 1px solid black;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
}

div.sectors div.sector:nth-child(2n+1)::after {
    content: " ";
    border-right: 1px solid black;
    height: 100%;
    width: calc(var(--gap) / 2);
    display: block;
    position: absolute;
    top: calc(var(--gap) / 2);
    right: calc(var(--gap) / 2 * -1 - 1px);
}

.sector span {
    box-sizing: border-box;
    padding-top: var(--gutter);
}

div.sectors div.sector:nth-child(2n+1) span {
    padding-left: var(--gutter);
}

.sector a {
    width: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* breathing space for images like figma */
.sector a img {
    max-width: calc(100% - var(--gutter2));
    object-fit: cover;
}

a.cta-container {
    display: block;
    position: absolute;
    width: 100vw;
    height: 100%;
    bottom: 0;
    right: 0;
}

a.cta-container :deep(img) {
    bottom: 0;
    height: 90%;
    max-height: none;
}

@media (max-width: 960px) {
    div.filter label span {
        display: inline-block;
        font-size: 2.5vmax;
    }

    div.sectors {
        overflow-x: hidden;
    }

    div.sectors div.sector {
        width: 100% !important;
    }

    div.sectors div.sector::after {
        border: none !important;
    }

    div.sectors div.sector span {
        padding-left: var(--gutter);
        padding-right: var(--gutter);
    }
}
</style>