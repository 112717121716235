import * as VueRouter from 'vue-router'
import Home from './components/Home.vue'
import About from './components/About.vue'
import DreamCube from './components/DreamCube.vue'
import PartnerWithUs from './components/PartnerWithUs.vue'
import FutureMakers from './components/FutureMakers.vue'
import Chat from './components/Chat.vue'
import ChatNew from './components/ChatNew.vue'
import Sector from './components/Sector.vue'
import CaseStudy from './components/CaseStudy.vue'
import SectorIndex from './components/SectorIndex.vue'
import Export from './components/Export.vue'
import NotFound from './components/NotFound.vue'

export default VueRouter.createRouter({
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    },
    history: VueRouter.createWebHistory(),
    routes: [
        { path: "/", component: Home },
        { path: "/dreamcube", component: DreamCube },
        { path: "/about", component: About },
        { path: "/futuremakers", component: FutureMakers },
        { path: "/partnerwithus", component: PartnerWithUs },
        { path: "/chat/new", component: ChatNew },
        { path: "/chat/:id", component: Chat, props: true },
        { path: "/c/:slug", component: Sector, props: true },
        { path: "/c/:sectorSlug/:caseStudySlug", component: CaseStudy, props: true },
        { path: "/categories", component: SectorIndex, props: true },
        { path: "/export", component: Export },
        { path: "/404", component: NotFound },
        { path: "/:catchAll(.*)", redirect: "/404" }
    ]
})