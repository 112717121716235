<template>
    <a class="share-icon" @click="copyTextToClipboard(shareURL, $event)" title="Copy link">
        <FontAwesomeIcon icon="fa-link" />
    </a>
    <ShareNetwork v-if="shareURL" v-for="network of shareNetworks" class="share-icon" :network="network.name"
        :url="shareURL">
        <FontAwesomeIcon :icon="network.icon" :title="`Share via ${network.name}`" />
    </ShareNetwork>
</template>

<script>
import { assetURL, shareNetworks, copyTextToClipboard } from "../mixins.js"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
    props: ['url'],
    components: { FontAwesomeIcon },
    methods: { copyTextToClipboard },
    computed: { 
        shareNetworks,
        shareURL() {
            return this.url || location.href
        }
     }
}
</script>

<style scoped>
.share-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 4vmax;
    height: 4vmax;
    cursor: pointer;
    border-radius: 100%;
    padding: 0px !important;
    box-sizing: border-box;
    border: 1px solid black;
    background-color: white;
    color: black;
    font-size: 1.5vmax;
}

@media (max-width: 960px) {
    .share-icon {
        width: 5vmax;
        height: 5vmax;
        font-size: 2vmax;
    }
}
</style>