<template>
    <h1>Script</h1>
    <dl v-for="(value, prop) in script">
        <dt>{{ prop }}</dt>
        <dd v-html="render(value)"></dd>
    </dl>
    <h1>Sectors</h1>
    <div v-for="c in sectors">
        <dl v-for="(value, prop) in c">
            <dt>{{ prop }}</dt>
            <dd v-html="render(value)"></dd>
        </dl>
        <hr>
    </div>
</template>

<script>

import api from '@accelerator/common/crm-api.js'
import * as env from "../env.js"

const apiServerURL = new URL(env.API_SERVER, document.baseURI).toString()

const { sector, script } = api(apiServerURL)

export default {
    data() {
        return {
            sectors: null,
            script: null
        }
    },
    methods: {
        render(v) {
            if (typeof v === 'number')
                return v
            if (typeof v === 'string')
                return v
            if (typeof v === 'function') {
                const params = v.toString().match("(.*)\s*=>")[1].replaceAll(/[\(\)]/g, "").split(",").map(p => `$${p.trim().toUpperCase()}`)
                return v.apply(null, params)
            }
            if (Array.isArray(v))
                return `<ol>${v.map(v => `<li>${this.render(v)}</li>`).join("\n")}</ol>`
            if (v)
                return `<dl>${Object.entries(v).map(([k, v]) => `<dt>${k}</dt><dd>${this.render(v)}</dd>`).join("\n")}</dl>`
            return JSON.stringify(v)
        }
    },
    async mounted() {
        this.sectors = await sector.getAll()
        this.script = await script.get()
    }
}
</script>

<style scoped>
:deep(dt) {
    font-weight: bold;
}
</style>