<template>
    <section>
        <BeatLoader color="black" />
    </section>
</template>

<script>
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'

export default {
    components: { BeatLoader }
}
</script>

<style scoped>
section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100% - var(--header-height));
}
</style>