<template>
    <HeaderSite message="The Accelerator" :repeats="12" />
    <PageLoading v-if="!content" />
    <main v-if="content">
        <h2>{{ content?.name }}</h2>
        <img class="hero" v-if="content?.image" :src="assetURL(content?.image)">
        <img class="hero" v-else src="https://placehold.co/600x400">
        <div v-if="content?.introduction" class="introduction row">
            <div class="col-6 col-3-xs">
                <p v-html="content.introduction"></p>
            </div>
        </div>
        <div class="content row wide full hide-on-mobile">
            <hr>
            <hr>
        </div>
        <div class="content row">
            <hr class="hide-on-desktop col-3-xs">
            <div v-if="content?.fact" class="column col-6 col-3-xs">
                <p><strong>Why</strong></p>
                <p>
                    <span v-html="smartquotes(content?.fact)"></span>
                    <a target="_blank" class="fact-source" :href="content?.fact_source">source</a>
                </p>
            </div>
            <hr class="hide-on-desktop col-3-xs">
            <div v-if="content?.gameplan_before || content?.gameplan_after || content?.solutions"
                class="column col-6 col-3-xs">
                <p><strong>Actions</strong></p>
                <p>
                <ul>
                    <li v-for="bullet in content?.gameplan_before">
                        {{ smartquotes(bullet.text) }}
                    </li>
                    <li v-for="solution in content?.solutions">
                        <a v-if="solution.source" target="_blank" :href="solution.source">
                            {{ smartquotes(solution.long_description) }}
                        </a>
                        <span v-else>{{ smartquotes(solution.long_description) }}</span>
                    </li>
                    <li v-for="bullet in content?.gameplan_after">
                        {{ smartquotes(bullet.text) }}
                    </li>
                </ul>
                </p>
            </div>
        </div>
        <div class="content row wide full hide-on-mobile">
            <hr>
            <hr>
        </div>
        <div class="content row">
            <hr class="hide-on-desktop col-3-xs">
            <div v-if="content?.resources" class="column col-6 col-3-xs">
                <p><strong>Use These</strong></p>
                <p>
                <ul>
                    <li v-for="resource in content?.resources">
                        <a target="_blank" :href="resource.url">{{ smartquotes(resource.text) }}</a>
                    </li>
                </ul>
                </p>
            </div>
            <hr class="hide-on-desktop col-3-xs">
            <div class="column col-6 col-3-xs">
                <p><strong>First Steps</strong></p>
                <p v-if="content?.first_steps" v-html="content?.first_steps"></p>
            </div>
        </div>
        <NewsletterDivider />
        <h2 class="future-makers" v-if="content?.case_studies">FutureMakers</h2>
        <div class="columns row case-studies" v-for="caseStudy of content?.case_studies">
            <div class="column col-6 case-study">
                <img v-if="caseStudy.blurb_image" :src="assetURL(caseStudy.blurb_image.key)">
                <img v-else src="/images/missing-person-white.svg">

            </div>
            <div class="col-6 col-3-xs case-study text">
                <div>
                    <h3 v-if="caseStudy.slug"><a :href="caseStudyURL(slug, caseStudy.slug)">{{ caseStudy.title }}</a></h3>
                    <h3 v-else>{{ caseStudy.title }}</h3>
                    <div class="column" v-if="caseStudy.blurb_description">
                        <p v-html="caseStudy.blurb_description"> </p>
                    </div>
                    <p v-if="caseStudy.slug">
                        <a class="learn-more body-text" :href="caseStudyURL(slug, caseStudy.slug)">
                            Learn more
                        </a>
                    </p>
                </div>
                <div class="share">
                    <SocialMediaShareButtons :url="caseStudyURL(slug, caseStudy.slug, true)" />
                </div>
            </div>
        </div>
    </main>
    <Footer :border="!!content?.case_studies" />
</template>

<script>
import smartquotes from 'smartquotes'
import PageLoading from './PageLoading.vue';
import HeaderSite from './HeaderSite.vue';
import Footer from './Footer.vue';
import NewsletterDivider from './NewsletterDivider.vue';
import SocialMediaShareButtons from './SocialMediaShareButtons.vue';
import api from '@accelerator/common/crm-api.js'
import * as env from "../env.js"
import { assetURL, shareNetworks, copyTextToClipboard, caseStudyURL } from "../mixins.js"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const apiServerURL = new URL(env.API_SERVER, document.baseURI).toString()

const { sector } = api(apiServerURL)

export default {
    components: { HeaderSite, Footer, FontAwesomeIcon, NewsletterDivider, SocialMediaShareButtons, PageLoading },
    props: ['slug'],
    methods: {
        assetURL,
        smartquotes,
        caseStudyURL,
        copyTextToClipboard
    },
    data() {
        return {
            content: null
        }

    },
    computed: {
        shareNetworks
    },
    async mounted() {
        this.content = await sector.get(this.slug)
        if (!this.content) {
            this.$router.replace("/404")
        }
    }
}
</script>

<style scoped>
@import url("./content.css");

.fact-source {
    letter-spacing: 0 !important;
}

h2 {
    padding: var(--gutter);
}

img.hero {
    width: 100%;
    max-height: var(--sector-hero-image-height);
    object-fit: cover;
    margin-bottom: var(--gutter);
}

.introduction {
    margin-bottom: var(--column);
}

.content {
    gap: var(--gutter);
}

.content hr {
    border: none;
    margin: 0;
    margin-bottom: var(--gutter);
    border-top: 1px solid black !important;
    width: calc(50% - var(--gutter) /2);
}

@media (max-width: 960px) {
    h2 {
        padding: var(--gutter) !important;
        line-height: 1em !important;
    }

    .introduction {
        margin-bottom: var(--gutter2);
    }

    .content.row.wide div {
        padding-left: var(--gutter);
    }

    .content.row {
        gap: 0;
    }

    .column {
        padding-bottom: 0;
    }
}

.content p {
    /* margin-bottom: var(--gutter) */
}


.content>*:first-child {
    /* padding-left: var(--gutter); */
}

.content>*:last-child {
    /* padding-right: var(--gutter); */
}


@media (max-width: 960px) {
    hr {
        margin-top: calc(var(--sector-body-padding) * -1 + var(--sector-header-height) / 2);
    }
}

.future-makers {
    padding-top: var(--column);
    padding-bottom: var(--column)
}

.case-studies {
    margin-bottom: var(--column)
}

.case-study {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    justify-content: space-between;
}

.case-study a {
    text-decoration: none;
}

.case-study h3 {
    padding-bottom: var(--gutter);
}

@media (max-width: 960px) {
    .case-studies {
        margin-bottom: var(--gutter2)
    }

    .case-study {
        /* min-height: calc(var(--column) * 2); */
        gap: var(--gutter);
    }

    .case-study p {
        line-height: 2em !important;
    }

    .case-study h3 {
        padding-bottom: var(--gutter2);
    }

    .case-study.text {
        box-sizing: border-box;
        padding-left: var(--gutter);
    }
}

a.learn-more {
    border: 1px solid black;
    border-radius: 2em;
    padding: 0.25em 0.75em;
    transition: background-color 1s, color 1s;
    background-color: white;
    color: black !important;
}

a.learn-more:hover {
    background-color: black;
    color: white !important;
}

div.share {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    margin-right: var(--gutter);
    margin-top: var(--gutter);
    gap: var(--social-media-gap);
}
</style>