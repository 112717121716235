<template>
    <HeaderSite />
    <PageLoading v-if="!content" />
    <div class="video-container" v-if="content">
        <video :src="assetURL(content.video)" autoplay loop muted></video>
        <section class="share">
            <SocialMediaShareButtons url="https://theaccelerator.studio/dreamcube" />
        </section>
    </div>
    <main v-if="content">
        <section class="row header">
            <h3 class="col-8 col-3-xs">
                {{ content.header }}
            </h3>
        </section>
        <section class="row">
            <h5 class="body col-6 col-3-xs" v-html="content.body"></h5>
        </section>
    </main>
    <Footer />
</template>

<script>
import PageLoading from './PageLoading.vue'
import HeaderSite from './HeaderSite.vue'
import Footer from './Footer.vue'
import SocialMediaShareButtons from './SocialMediaShareButtons.vue'
import api from '@accelerator/common/crm-api.js'
import * as env from "../env.js"
import { assetURL, shareNetworks } from '../mixins'

const apiServerURL = new URL(env.API_SERVER, document.baseURI).toString()
const { dreamcube } = api(apiServerURL)

const fadeStartTimeout = 8 * 1000

export default {
    data() {
        return {
            /** @type {any} */
            content: null,
        }
    },
    components: { HeaderSite, Footer, SocialMediaShareButtons, PageLoading },
    methods: { 
        assetURL,
     },
    computed: { shareNetworks },
    async mounted() {
        this.content = await dreamcube.get()
        console.log(this.content)
    },
}
</script>

<style scoped>
main {
    /* height: calc(100vh - var(--header-height)); */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background-color: white;
    padding-top: var(--gutter);
    padding-bottom: var(--gutter);
    gap: var(--gutter);
}

div.video-container {
    width: var(--app-width);
    height: calc(100vh - var(--header-height));
    z-index: -1;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
}

video {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

section.footer {
    color: black;
    background-color: purple;
    padding-bottom: var(--gutter);
    justify-content: space-between;
}

section.share {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    box-sizing: border-box;
    margin-right: var(--gutter);
    margin-bottom: var(--gutter);
    gap: var(--gutter);
}

@media (max-width: 960px) {
    main {
        padding-top: var(--gutter2);
    }

    section.header {
        padding-bottom: var(--gutter);
    }

    section.footer {
        flex-direction: column;
    }

    section.body {
        width: 100%;
    }
}
</style>