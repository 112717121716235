<template>
    <main>
        <BeatLoader color="black" />
        <vue-turnstile site-key="0x4AAAAAAARge4ZxNOgjJshT" v-model="token" />
    </main>
</template>

<script>
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
import VueTurnstile from 'vue-turnstile';
import * as socket from '../socket.js'
import * as env from '../env.js'

const ws = socket.connect(env.SOCKET_SERVER)

export default {
    data() {
        return {
            token: ''
        }
    },
    components: { BeatLoader, VueTurnstile },
    watch: {
        token() {
            ws.participant.create.emit(this.token)
            ws.participant.created.on(({ chat }) => {
                window.location = `/chat/${chat}`
            })
        }
    }
}

</script>

<style scoped>
main {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>