<template>
  <GridOverlay />
  <router-view />
</template>

<script>
import GridOverlay from './GridOverlay.vue';

export default {
  components: { GridOverlay },
  methods: {
    resize() {
      document.documentElement.style.setProperty('--app-width', document.querySelector("#app")?.clientWidth + 'px')
    }
  },
  mounted() {
    this.resize()
    window.addEventListener('resize', this.resize.bind(this))
  }
}
</script>

<style>
:root {
  --column-count: 12;
  --gutter: 2.22vw;
  --gutter2: calc(var(--gutter) * 2);
  --gutter3: calc(var(--gutter) * 3);

  /* --column: calc((100% / var(--column-count) * 1) - var(--gutter)); */
  --column: calc((var(--app-width) / var(--column-count) * 1) - var(--gutter));

  --header-row-height: 80px;
  --header-content-height: 40px;
  --header-height: calc(var(--header-row-height) * 2);

  --homepage-header-padding: 5vh;
  --homepage-section-font-size: clamp(18pt, 3vw, 42pt);
  --link-color: black;
  --link-hover-color: rgb(160, 160, 160);
  --header-font-size: max(3vmin, 18pt);
  --header-menu-font-size: max(2vmin, 15pt);
  --header-gap: 1vmax;

  --sector-header-font-size: 5vmin;
  --sector-header-height: 12vh;

  --sector-hero-image-height: 65vh;

  --sector-body-font-size: clamp(14pt, 2.25vmin, 22pt);
  --sector-body-line-height: max(2.9vmin, 18pt);
  --sector-body-gap: clamp(20px, 5vh, 50px);
  --sector-body-list-gap: 0.5em;
  --sector-body-padding: 3vh;
  --sector-body-paragraph-margin: 1em;
  --sector-body-heading-margin: 1vh;

  --index-image-margin: 13vmin;
  --index-header-font-size: 12vw;

  --footer-height: 40vh;
  --footer-heading-font-size: min(48pt, 4vw);
  --footer-font-size: var(--sector-body-font-size);

  --future-makers-gap: clamp(20px, 2.5vmin, 50px);

  --about-heading-padding: var(--sector-body-gap);

  --social-media-gap: 2vw;

  --header-logo-font-size: 20vmin;
}

@media (max-width: 1280px) {
  :root {
    --homepage-header-padding: 1vh;
    --header-row-height: max(8vh, 75px);
    --header-font-size: max(3vmax, 18pt);
    --header-menu-font-size: max(4vh, 15pt);
    --header-gap: 2vmax;
    --header-logo-font-size: 20vmin;
    --header-height: calc(var(--header-logo-font-size) + var(--gutter) * 2);
  }
}

@media (max-width: 960px) {
  :root {
    --column-count: 3;
    --gutter: 4.6vw;
    
    --sector-header-font-size: 4vmax;
    --sector-body-font-size: max(2.25vmax, 14pt);
    --sector-body-line-height: max(2.75vmax, 15pt);

    --sector-body-gap: 0;

    --index-image-margin: calc(var(--sector-body-padding) * 2);

    --footer-height: 45vh;
    /* --footer-font-size: 1.8vh; */

    --future-makers-gap: clamp(10px, 1vmin, 20px);

    --about-heading-padding: 2vh;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gutter);
  box-sizing: border-box;
  padding-left: var(--gutter);
}

.row.wide {
  padding-left: 0;
}

.row.wide.full {
  width: var(--app-width);
  justify-content: space-between;

}

.row-8 {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gutter);
  box-sizing: border-box;
  --column-count: 8;
}

.col {
  flex: 0 0 calc((100% / var(--column-count) * 1) - var(--gutter));
  min-width: calc((100% / var(--column-count) * 1) - var(--gutter));
}

.col-1 {
  flex: 0 0 calc((100% / var(--column-count) * 1) - var(--gutter));
  min-width: calc((100% / var(--column-count) * 1) - var(--gutter));
}

.col-2 {
  flex: 0 0 calc((100% / var(--column-count) * 2) - var(--gutter));
  min-width: calc((100% / var(--column-count) * 2) - var(--gutter));
}

.col-3 {
  flex: 0 0 calc((100% / var(--column-count) * 3) - var(--gutter));
  min-width: calc((100% / var(--column-count) * 3) - var(--gutter));
}

.col-4 {
  flex: 0 0 calc((100% / var(--column-count) * 4) - var(--gutter));
  min-width: calc((100% / var(--column-count) * 4) - var(--gutter));
}

.col-5 {
  flex: 0 0 calc((100% / var(--column-count) * 5) - var(--gutter));
  min-width: calc((100% / var(--column-count) * 5) - var(--gutter));
}

.col-6 {
  flex: 0 0 calc((100% / var(--column-count) * 6) - var(--gutter));
  min-width: calc((100% / var(--column-count) * 6) - var(--gutter));
}

.col-7 {
  flex: 0 0 calc((100% / var(--column-count) * 7) - var(--gutter));
  min-width: calc((100% / var(--column-count) * 7) - var(--gutter));
}

.col-8 {
  flex: 0 0 calc((100% / var(--column-count) * 8) - var(--gutter));
  min-width: calc((100% / var(--column-count) * 8) - var(--gutter));
}

.col-9 {
  flex: 0 0 calc((100% / var(--column-count) * 9) - var(--gutter));
  min-width: calc((100% / var(--column-count) * 9) - var(--gutter));
}

.col-10 {
  flex: 0 0 calc((100% / var(--column-count) * 10) - var(--gutter));
  min-width: calc((100% / var(--column-count) * 10) - var(--gutter));
}

.col-11 {
  flex: 0 0 calc((100% / var(--column-count) * 11) - var(--gutter));
  min-width: calc((100% / var(--column-count) * 11) - var(--gutter));
}

.col-12 {
  flex: 0 0 calc((100% / var(--column-count) * 12) - var(--gutter));
  min-width: calc((100% / var(--column-count) * 12) - var(--gutter));
}

.off-1 {
  margin-left: calc((100% / var(--column-count) * 1));
}

.off-2 {
  margin-left: calc((100% / var(--column-count) * 2));
}

.off-3 {
  margin-left: calc((100% / var(--column-count) * 3));
}

.off-4 {
  margin-left: calc((100% / var(--column-count) * 4));
}

.off-5 {
  margin-left: calc((100% / var(--column-count) * 5));
}

.off-6 {
  margin-left: calc((100% / var(--column-count) * 6));
}

.off-7 {
  margin-left: calc((100% / var(--column-count) * 7));
}

.off-8 {
  margin-left: calc((100% / var(--column-count) * 8));
}

.off-9 {
  margin-left: calc((100% / var(--column-count) * 9));
}

.off-10 {
  margin-left: calc((100% / var(--column-count) * 10));
}

.off-11 {
  margin-left: calc((100% / var(--column-count) * 11));
}

.off-12 {
  margin-left: calc((100% / var(--column-count) * 12));
}

.debug {
  background-color: orange !important;
}

.debug-1 {
  background-color: greenyellow !important;
}

.menu-logo {
  font-size: 40px;
  font-family: 'PP Editorial Old';
}

h1 {
  font-family: "PP Editorial Old" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 11.25rem !important;
  /* line-height: 11rem !important; */
  line-height: 0.85em !important;
  letter-spacing: -0.3375rem !important;
}

h2 {
  font-family: "Px Grotesk" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  text-align: center;
  font-size: 4rem !important;
  line-height: 4.5rem !important;
  letter-spacing: -0.1rem !important;
}

h3 {
  font-family: "PP Editorial Old" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 3.8rem !important;
  line-height: 4.25rem !important;
  letter-spacing: -0.10625rem !important;
}

.h4,
h4 {
  font-family: "Px Grotesk" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 2.4375rem !important;
  line-height: 3rem !important;
  letter-spacing: -0.07313rem !important;
}

p,
h5,
div.column ul li,
div.column ol li,
div.column h1,
div.column h2,
div.column h3,
div.column h4,
div.column h5,
div.column h6,
.body-text {
  font-family: "Px Grotesk" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 1.875rem !important;
  line-height: 2.4rem !important;
  letter-spacing: -0.04rem !important;
}

div.column h1,
div.column h2,
div.column h3,
div.column h4,
div.column h5,
div.column h6 {
  font-weight: bold !important;
}

.why-footer p,
h6 {
  font-family: "Px Grotesk" !important;
  font-style: normal !important;
  font-size: 1.1rem !important;
  line-height: 1.3rem !important;
  letter-spacing: -0.03rem !important;
}

a,
a {
  text-underline-offset: 0.075em;
  text-decoration-thickness: 1px;
  text-decoration-skip-ink: all;
  color: var(--link-color) !important;
  transition: color 1s;
}

a:hover,
a:hover {
  color: var(--link-hover-color) !important;
}


body,
html,
#app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  /* overflow-x: hidden; */
}

strong {
  font-weight: bold !important;
}

@media (min-width: 960px) {
  .hide-on-desktop {
    display: none !important;
  }
}

@media (max-width: 960px) {
  .wide-xs {
    padding-left: 0;
  }

  .wide-xs.full-xs {
    width: var(--app-width);
    justify-content: space-between;
  }


  .hide-on-mobile {
    display: none !important;
  }

  header.mobile a,
  h1 {
    font-size: var(--header-logo-font-size) !important;
    line-height: 0.95em !important;
    letter-spacing: 0 !important;
  }

  h3 {
    font-weight: 400 !important;
    font-size: 1.75rem !important;
    line-height: 2.2rem !important;
    letter-spacing: -0.02rem !important;
  }

  h2 {
    /* should match .column div :deep(blockquote p) in CaseStudies */
    font-size: 4vmax !important;
    line-height: 4vmax !important;
    letter-spacing: -0.075rem !important;
  }

  .h4,
  h4 {
    font-size: 5vw !important;
    line-height: 5.5vw !important;
    letter-spacing: 0 !important;
  }

  p,
  h5,
  .h5-xs,
  div.column ul li,
  div.column ol li,
  div.column h1,
  div.column h2,
  div.column h3,
  div.column h4,
  div.column h5,
  div.column h6,
  .body-text {
    font-size: max(2.25vmax, 14pt) !important;
    line-height: max(2.75vmax, 15pt) !important;
    letter-spacing: -0.01rem !important;
  }

  .why-footer p,
  h6 {
    font-size: max(2.25vmax, 14pt) !important;
    line-height: max(2.75vmax, 15pt) !important;
    letter-spacing: -0.01rem !important;
  }

  .col-xs {
    flex: 0 0 calc((100% / var(--column-count) * 1) - var(--gutter));
    min-width: calc((100% / var(--column-count) * 1) - var(--gutter));
  }

  .col-1-xs {
    flex: 0 0 calc((100% / var(--column-count) * 1) - var(--gutter));
    min-width: calc((100% / var(--column-count) * 1) - var(--gutter));
  }

  .col-2-xs {
    flex: 0 0 calc((100% / var(--column-count) * 2) - var(--gutter));
    min-width: calc((100% / var(--column-count) * 2) - var(--gutter));
  }

  .col-3-xs {
    flex: 0 0 calc((100% / var(--column-count) * 3) - var(--gutter));
    min-width: calc((100% / var(--column-count) * 3) - var(--gutter));
  }

  .col-4-xs {
    flex: 0 0 calc((100% / var(--column-count) * 4) - var(--gutter));
    min-width: calc((100% / var(--column-count) * 4) - var(--gutter));
  }

  .col-5-xs {
    flex: 0 0 calc((100% / var(--column-count) * 5) - var(--gutter));
    min-width: calc((100% / var(--column-count) * 5) - var(--gutter));
  }

  .col-6-xs {
    flex: 0 0 calc((100% / var(--column-count) * 6) - var(--gutter));
    min-width: calc((100% / var(--column-count) * 6) - var(--gutter));
  }

  .col-7-xs {
    flex: 0 0 calc((100% / var(--column-count) * 7) - var(--gutter));
    min-width: calc((100% / var(--column-count) * 7) - var(--gutter));
  }

  .col-8-xs {
    flex: 0 0 calc((100% / var(--column-count) * 8) - var(--gutter));
    min-width: calc((100% / var(--column-count) * 8) - var(--gutter));
  }

  .col-9-xs {
    flex: 0 0 calc((100% / var(--column-count) * 9) - var(--gutter));
    min-width: calc((100% / var(--column-count) * 9) - var(--gutter));
  }

  .col-10-xs {
    flex: 0 0 calc((100% / var(--column-count) * 10) - var(--gutter));
    min-width: calc((100% / var(--column-count) * 10) - var(--gutter));
  }

  .col-11-xs {
    flex: 0 0 calc((100% / var(--column-count) * 11) - var(--gutter));
    min-width: calc((100% / var(--column-count) * 11) - var(--gutter));
  }

  .col-12-xs {
    flex: 0 0 calc((100% / var(--column-count) * 12) - var(--gutter));
    min-width: calc((100% / var(--column-count) * 12) - var(--gutter));
  }

  .off-0-xs {
    margin-left: 0;
  }

  .off-1-xs {
    margin-left: calc((100% / var(--column-count) * 1));
  }

  .off-2-xs {
    margin-left: calc((100% / var(--column-count) * 2));
  }

  .off-3-xs {
    margin-left: calc((100% / var(--column-count) * 3));
  }

  .off-4-xs {
    margin-left: calc((100% / var(--column-count) * 4));
  }

  .off-5-xs {
    margin-left: calc((100% / var(--column-count) * 5));
  }

  .off-6-xs {
    margin-left: calc((100% / var(--column-count) * 6));
  }

  .off-7-xs {
    margin-left: calc((100% / var(--column-count) * 7));
  }

  .off-8-xs {
    margin-left: calc((100% / var(--column-count) * 8));
  }

  .off-9-xs {
    margin-left: calc((100% / var(--column-count) * 9));
  }

  .off-10-xs {
    margin-left: calc((100% / var(--column-count) * 10));
  }

  .off-11-xs {
    margin-left: calc((100% / var(--column-count) * 11));
  }

  .off-12-xs {
    margin-left: calc((100% / var(--column-count) * 12));
  }

  .row-2-xs {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gutter);
    box-sizing: border-box;
    --column-count: 2;
  }

}

@font-face {
  font-family: 'IBM Plex Mono';
  font-weight: normal;
  src: url('/fonts/IBMPlexMono-Regular.woff') format('woff')
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-weight: bold;
  src: url('/fonts/IBMPlexMono-Bold.woff') format('woff')
}

@font-face {
  font-family: 'PP Editorial Old';
  font-weight: normal;
  src: url('/fonts/PPEditorialOld-Regular.woff') format('woff')
}
</style>