import { createDirectus, rest, staticToken, createItem, readItem, readItems, readSingleton, updateItem } from '@directus/sdk';
import debug from './debug.js'

const log = debug("crm")

function ngrokFriendlyFetch(input, init) {
    const headers = { ...init.headers || {}, "ngrok-skip-browser-warning": true }
    init = { ...init, headers }
    log.fetch(input, init)
    return fetch(input, init)
}

async function single(client, collection, field, value, fields = ['*']) {
    // @ts-ignore
    const matches = await client.request(readItems(collection, { fields, filter: { [field]: { _eq: value } } }))
    if (matches.length === 1) {
        return matches[0]
    } else {
        return null
    }
}

export default function (uri, token = null) {
    log.client(uri)
    const config = { globals: { fetch: ngrokFriendlyFetch } }
    const client =
        token
            ? createDirectus(uri, config).with(rest()).with(staticToken(token))
            : createDirectus(uri, config).with(rest())
    let cachedScript = null
    return {
        about: {
            async get() {
                return await client.request(readSingleton('about'))
            }
        },
        dreamcube: {
            async get() {
                return await client.request(readSingleton('dreamcube'))
            }
        },
        partnerwithus: {
            async get() {
                return await client.request(readSingleton('partnerwithus'))
            }
        },
        script: {
            /**
             * @returns {Promise<{
             *   sms_welcome: (url:string) => string,
             *   name_prompt: string,
             *   name_failure: string,
             *   future_biography_prompt: (name:string) => string,
             *   talk_about_my_future_button: string,
             *   find_my_climate_action_button: string,
             *   future_conversation_message_limit: number,
             *   future_conversation_start: string,
             *   future_conversation_maxed_out: string,
             *   future_conversation_limited_time: string,
             *   present_biography_prompt: string,
             *   future_self_story: (name:string, story:string) => string,
             *   future_self_story_conclusion: string,
             *   sector_guess_first: (sector:string) => string,
             *   sector_guess: (sector:string) => string,
             *   sector_missed_followup: string,
             *   sector_rejection_first: string,
             *   sector_buttons_prompt_category: string,
             *   sector_buttons_prompt_category_sector: string,
             *   sector_confirmation: (sector, sectorFact) => string,
             *   solution_buttons: string,
             *   solution_confirmation: (longSolution:string) => string,
             *   sector_link_share: (longSolution:string, url:string, linkText?:string) => string,
             *   ongoing_contact_consent: string,
             *   ongoing_contact_consent_accepted: string,
             *   ongoing_contact_consent_rejected: string,
             *   final_message: string,
             *   network_error: string,
             *   email_prompt: string,
             *   email_failure: string,
             * }>}
             */
            async get() {
                // TODO reenable cache
                // if (!cachedScript) {
                //     cachedScript = await client.request(readItem('script', 1))
                // }
                const cachedScript = await client.request(readSingleton('script'))
                return {
                    ...cachedScript,
                    sms_welcome: url => cachedScript.sms_welcome.replaceAll("$URL", url),
                    future_biography_prompt: name => cachedScript.future_biography_prompt.replaceAll("$NAME", name),
                    future_self_story: (name, story) => cachedScript.future_self_story.replaceAll("$NAME", name).replaceAll("$STORY", story),
                    sector_guess_first: sector => cachedScript.sector_guess_first.replaceAll("$SECTOR", sector),
                    sector_guess: sector => cachedScript.sector_guess.replaceAll("$SECTOR", sector),
                    sector_confirmation: (sector, sectorFact) => cachedScript.sector_confirmation.replaceAll("$SECTOR_FACT", sectorFact).replaceAll("$SECTOR", sector),
                    solution_confirmation: longSolution => cachedScript.solution_confirmation.replaceAll("$LONG_SOLUTION", longSolution),
                    sector_link_share: (longSolution, url, linkText = url) => cachedScript.sector_link_share.replaceAll("$URL", `<a target='_blank' href="${url}">${linkText}</a>`).replaceAll("$LONG_SOLUTION", longSolution)
                }
            }
        },
        sector: {
            async getAll(fields = ["*"]) {
                return await client.request(readItems('sectors', { fields, sort: ["name"] }))
            },
            async get(slug) {
                const matches = await client.request(readItems('sectors', { fields: ["*"], filter: { slug: { _eq: slug } } }))
                if (matches.length === 1) {
                    return matches[0]
                } else {
                    return null
                }
            }
        },
        caseStudy: {
            async get(sectorSlug, caseStudySlug) {
                const sector = await single(client, 'sectors', 'slug', sectorSlug)
                if (!sector) return null
                return sector.case_studies.find(c => c.slug === caseStudySlug) || null
            }
        },
        participant: {
            async get(id) {
                return await client.request(readItem('participants', id, { fields: ["*", "sector.*"] }))
            },
            async reset(id) {
                return await client.request(updateItem('participants', id, { name: null, future_biography: null, present_biography: null, sector: null, solution: null }))
            },
            async updateName(id, name) {
                return await client.request(updateItem('participants', id, { name }))
            },
            async updateEmail(id, email) {
                return await client.request(updateItem('participants', id, { email }))
            },
            async updateContactConsent(id, contact_consent) {
                return await client.request(updateItem('participants', id, { contact_consent }))
            },
            async updateActivities(id, activities) {
                return await client.request(updateItem('participants', id, { activities: activities.map(text => ({ text })) }))
            },
            async updateFutureBiography(id, future_biography) {
                return await client.request(updateItem('participants', id, { future_biography }))
            },
            async updatePresentBiography(id, present_biography) {
                return await client.request(updateItem('participants', id, { present_biography }))
            },
            async updateSector(id, sector) {
                return await client.request(updateItem('participants', id, { sector }))
            },
            async updateShortSolution(id, solution) {
                return await client.request(updateItem('participants', id, { solution }))
            },
            async getOrCreateWithPhoneNumber(phone_number) {
                const matches = await client.request(readItems('participants', { filter: { phone_number: { _eq: phone_number } } }))
                if (matches.length === 0) {
                    // first time seeing this number, create new participant with phone number
                    return await client.request(createItem('participants', { phone_number }))
                } else {
                    // seen this number before, reuse existing participant
                    return matches[0]
                }
            },
            async createBlank() {
                return await client.request(createItem('participants', {}))
            }
        },
        chat: {
            async addCost(id, amount) {
                const { cost } = await client.request(readItem('chats', id, { fields: ['cost'] }))
                log[id].addCost(cost, amount)
                return await client.request(updateItem('chats', id, { cost: cost + amount }))
            },
            async reset(id) {
                return await client.request(updateItem('chats', id, { cost: 0, history: [] }))
            },
            async getOrCreate(participant) {
                const matches = await client.request(readItems('chats', { filter: { participant } }))
                if (matches.length === 0) {
                    // no chat with this participant, create new chat
                    return client.request(createItem('chats', { participant }))
                } else {
                    // chat exists, reuse
                    return matches[0]
                }
            },
            async get(id) {
                try {
                    return await client.request(readItem('chats', id, { fields: ['*', 'participant.*'] }))
                } catch (e) {
                    return null
                }
            },
            async pushHistory(id, messages) {
                const { history } = await client.request(readItem('chats', id, { fields: ['history'] }))
                const newHistory = history.concat(messages)
                await client.request(updateItem('chats', id, { history: newHistory }))
            },
            async finish(id) {
                await client.request(updateItem('chats', id, { finished: true }))
            }
        }
    }
}