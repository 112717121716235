<template>
    <HeaderSite :show-signup="true" />
    <div @click="clickThrough" class="carousel">
        <div data-link="/categories" :class="['slide', '_1', { active: activeSlide === 1 }]">
            <h1>Find Your<br>Action</h1>
            <div class="row wide">
                <p class="col-5 col-3-xs">Use AI to unlock where can you make the most impact with your limited time</p>                
            </div>
            <!-- <video muted loop playsinline src="/videos/step-into-the-future.mp4" /> -->
            <img src="/images/carousel-a.png">
        </div>
        <div data-link="/dreamcube" :class="['slide', '_2', { active: activeSlide === 2 }]">
            <h1>Step Into<br>Tomorrow</h1>
            <div class="row wide">
                <p class="col-5 col-3-xs">Step into the DreamCube and see the future change from bad to good</p>
            </div>
            <div class="overlay"></div>
            <img src="/images/carousel-b.png">
        </div>
        <div data-link="/futuremakers" :class="['slide', '_3', { active: activeSlide === 3 }]">
            <h1>Be a Future<wbr>Maker</h1>
            <div class="row wide">
                <p class="col-5 col-3-xs">Connect with others who are working in your same community </p>
            </div>
            <img src="/images/carousel-c.png">
        </div>
    </div>
    <div @click="advance" :class="['dots', activeSlideClass]">
        <svg :class="{ active: activeSlide === 1 }" width="20" height="20">
            <circle cx="10" cy="10" r="10" />
        </svg>
        <svg :class="{ active: activeSlide === 2 }" width="20" height="20">
            <circle cx="10" cy="10" r="10" />
        </svg>
        <svg :class="{ active: activeSlide === 3 }" width="20" height="20">
            <circle cx="10" cy="10" r="10" />
        </svg>
    </div>
    <main>
        <h2>
            Three Steps Into Making<br>a Better Tomorrow
        </h2>
        <section class="image"> <img src="/images/home-1.png"> </section>
        <section class="row">
            <h3 class="col-8 off-2 col-3-xs off-0-xs">
                Find Your Action in communities where you can make change: where you
                work, where your kids go to school, where you live. Use <a href="/chat/new">AI to find
                your action</a>, or browse them <a href="/categories">here</a>.
            </h3>
        </section>
        <section class="image"> <img src="/images/home-2.png"> </section>
        <section class="row">
            <h3 class="col-8 off-2 col-3-xs off-0-xs">
                For venues using the DreamCube, visitors can see the impact of their
                actions, stepping into an immersive hyper-local future. To bring the
                DreamCube to you, <a href="/partnerwithus">partner with us</a>.
            </h3>
            <h5 class="col-5 off-2 col-3-xs off-0-xs">
                The vision is not always positive or negative, but it changes
                based on your actions, and others.  We can make choices to turn
                the future from bad to good.
            </h5>
        </section>
        <section class="image"> <img src="/images/home-3.png"> </section>
        <section class="row">
            <h3 class="col-8 off-2 col-3-xs off-0-xs">
                Get inspired by FutureMakers, a surprising community of ordinary
                people who have taken concrete action to make the future better.
            </h3>
            <h5 class="col-5 off-2 col-3-xs off-0-xs">
                FutureMakers’ stories are accompanied by the resources, decks,
                gameplans, advice and first steps they recommend to anyone to
                start.
            </h5>
        </section>
    </main>
    <Footer />
</template>

<script>
import HeaderSite from './HeaderSite.vue';
import NewsletterPopup from './NewsletterPopup.vue';
import Footer from './Footer.vue';

export default {
    components: { HeaderSite, NewsletterPopup, Footer },
    data() {
        return {
            slide: 2,
            timeout: null
        }
    },
    computed: {
        activeSlide() {
            return this.slide + 1
        },
        activeSlideClass() {
            return `_${this.activeSlide}`
        }
    },
    watch: {
        activeSlideClass() {
            document.querySelector(`.${this.activeSlideClass} video`)?.play()
        }
    },
    methods: {
        clickThrough() {
            const slide = document.querySelector(`.${this.activeSlideClass}`)
            const link = slide?.getAttribute('data-link')
            this.$router.push(link)
        },
        advance() {
            if (this.timeout)
                clearTimeout(this.timeout)
            this.slide = (this.slide + 1) % 3
            this.timeout = setTimeout(() => {
                this.advance()
            }, 1000 * 10);
        }
    },
    mounted() {
        this.advance()
    },
    beforeUnmount() {
        clearTimeout(this.timeout)
    }
}
</script>

<style scoped>
div.carousel {
    height: calc(100vh - var(--header-height));
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

div.slide {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 1.5s;
    position: absolute;
    color: white;
}

div.slide.active {
    opacity: 1;
}

div.slide h1 {
    margin-left: var(--gutter);
    margin-right: var(--gutter);
    margin-top: var(--gutter);
}


div.slide p {
    font-family: 'Px Grotesk';
    font-size: clamp(18pt, 1.5vw, 28pt);
    padding-top: var(--gutter);
    padding-left: var(--gutter);
}

div.slide video,
div.slide img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    z-index: -1;
}

div.dots {
    --width: 100px;
    position: relative;
    z-index: 2;
    bottom: 5vmax;
    left: 0;
    display: flex;
    justify-content: space-around;
    margin: 0 calc((100vw - var(--width)) * 0.5);
    cursor: pointer;
    width: var(--width);
    height: 0;
}

div.dots svg {
    transform: scale(0.75);
    opacity: 0.5;
    transition: opacity 1.5s;
}

div.dots._3 svg circle,
div.dots._2 svg circle,
div.dots._1 svg circle {
    fill: white;
}

div.slide._2 * {
    z-index: 2;
}

div.slide._2 img {
    z-index: 0;
}

div.slide._2 .overlay {
    display: block;
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(180deg, rgba(159,159,159,1) 0%, rgba(255,255,255,0) 100%);
}

div.dots svg.active {
    opacity: 1;
}

@media (max-width: 960px) {
    div.slide h1 {
        font-size: 20vmin;
    }

    div.slide._3 img {
        bottom: 10vh;
    }
}

main {
    margin-top: var(--column);
}

@media (max-width: 960px) {
    main {
        margin-top: var(--column);
    }

    main section.image:first-of-type {
        margin-top: var(--column) !important;
    }

    h2 {
        /* padding: var(--gutter); */
    }
}

main section.image {
    margin-top: var(--column);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 80vh;
    padding: 0;
}

main section.image img {
    display: block;
    width: 100vw;
    object-fit: cover;
}

main section {
    box-sizing: border-box;
    margin-bottom: var(--column);
}

main section a,
main section a:visited {
    color: black;
} 

main section p {
    font-family: 'Px Grotesk';
    font-size: 0.7em;
    width: 80%;
    margin-top: var(--gutter);
}
</style>