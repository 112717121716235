/**
 * A chat message
 * @typedef {({body:string,source:'user'|'bot'|'future',timestamp?:number,state?:string})} ChatMessage
 */

export default function (socket) {
    return {
        disconnect: {
            /**
             * @param {()=>any} cb 
             */
            on(cb) {
                socket.on('disconnect', cb)
            }
        },
        connect: {
            /**
             * @param {()=>any} cb 
             */
            on(cb) {
                socket.on('connect', cb)
            }
        },
        participant: {
            create: {
                /**
                 * @todo validate turnstile
                 * @param {(token:string)=>any} cb 
                 */
                on(cb) { socket.on('participant:create', cb) },
                /**
                 * @param {string} token 
                 */
                emit(token) { socket.emit('participant:create', token) }
            },
            created: {
                /**
                 * @param {(payload:{participant:string, chat:string})=>any} cb 
                 */
                on(cb) { socket.on('participant:created', cb) },
                /**
                 * @param {string} participant
                 * @param {string} chat
                 */
                emit(participant, chat) { socket.emit('participant:created', { participant, chat }) }
            }
        },
        chat: {
            typing: {
                /**
                 * @param {(payload:boolean)=>any} cb 
                 */
                on(cb) { socket.on('chat:typing', cb) },
                /**
                 * 
                 * @param {boolean} payload 
                 */
                emit(payload) { socket.emit('chat:typing', payload) }
            },
            message: {
                /**
                 * @param {(payload:ChatMessage)=>any} cb 
                 */
                on(cb) { socket.on('chat:message', cb) },
                /**
                 * 
                 * @param {ChatMessage} payload 
                 */
                emit(payload) { socket.emit('chat:message', payload) }
            },
            start: {
                /**
                 * @param {(payload:{id:string,messageCount:number})=>any} cb 
                 */
                on(cb) { socket.on('chat:start', cb) },
                emit(id, messageCount) { socket.emit('chat:start', { id, messageCount }) }
            },
            messages: {
                /**
                 * @param {(payload:{messages:ChatMessage[]})=>any} cb 
                 */
                on(cb) { socket.on('chat:messages', cb) },
                emit(messages) { socket.emit('chat:messages', { messages }) }

            },
            notFound: {
                /**
                 * @param {()=>any} cb 
                 */
                on(cb) { socket.on('chat:not-found', cb) },
                emit() { socket.emit('chat:not-found') }
            },
            finished: {
                /**
                 * @param {()=>any} cb 
                 */
                on(cb) { socket.on('chat:finished', cb) },
                emit() { socket.emit('chat:finished') }
            },
            networkError: {
                /**
                 * @param {(message:string)=>any} cb 
                 */
                on(cb) { socket.on('chat:network-error', cb) },
                emit(message) { socket.emit('chat:network-error', message) }
            }
        },
    }
}