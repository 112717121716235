<template>
    <form
        action="https://Studio.us21.list-manage.com/subscribe/post?u=0c6e942206b9f73a9b181972e&amp;id=191067b815&amp;f_id=008bf2e6f0"
        method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate"
        target="_blank">
        <input type="email" placeholder="you@youremail.com" name="EMAIL" class="required email body-text" id="mce-EMAIL"
            required value="">
        <div aria-hidden="true" style="position: absolute; left: -5000px;">
            <input type="text" name="b_0c6e942206b9f73a9b181972e_191067b815" tabindex="-1" value="">
        </div>
        <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button body-text" value="Sign Up">
    </form>
</template>

<style scoped>
form {
    display: flex;
    gap: var(--gutter);
    flex-direction: row;
}

form input {
    box-sizing: border-box;
}

form input[type=submit],
form input[type=email] {
    border: 1px solid black;
    border-radius: 2em;
    padding: 0.4em 0.8em;
}

form input[type=submit] {
    color: white;
    background-color: black;
    cursor: pointer;
}

form input[type=email]::placeholder {
    color: #D9D9D9;
}
</style>