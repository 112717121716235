<template>
    <HeaderSite message="The Accelerator" :repeats="12" />
    <PageLoading v-if="!content" />
    <h2 v-if="sector && content">
        <a :href="sectorHref">{{ sector.name }}</a>
        <span class="slash hide-on-mobile">&mdash;</span>
        <br class="hide-on-desktop">
        <span>{{ content.title }}</span>
    </h2>
    <div v-if="content" class="row">
        <div class="column col-6 col-3-xs left">
            <div v-html="content.left"></div>
            <div class="share">
                <SocialMediaShareButtons />
            </div>
        </div>
        <div class="column col-6 col-3-xs right">
            <div v-html="content.right"></div>
        </div>
    </div>
    <Footer />
</template>

<script>
import PageLoading from './PageLoading.vue';
import HeaderSite from './HeaderSite.vue';
import Footer from './Footer.vue';
import SocialMediaShareButtons from './SocialMediaShareButtons.vue';
import api from '@accelerator/common/crm-api.js'
import * as env from "../env.js"

const apiServerURL = new URL(env.API_SERVER, document.baseURI).toString()

const { sector, caseStudy } = api(apiServerURL)

export default {
    components: { HeaderSite, Footer, SocialMediaShareButtons, PageLoading },
    props: ['sectorSlug', 'caseStudySlug'],
    data() {
        return {
            content: null,
            sector: null
        }

    },
    computed: {
        sectorHref() {
            return `/c/${this.sectorSlug}`
        }
    },
    async mounted() {
        this.sector = await sector.get(this.sectorSlug)
        this.content = await caseStudy.get(this.sectorSlug, this.caseStudySlug)
        if (!this.content || !this.sector) {
            this.$router.replace("/404")
        }
    }
}
</script>

<style scoped>
@import url("./content.css");

h2 {
    padding: var(--gutter) 0 var(--column) 0;
}

h2 span.slash {
    margin: 0 1rem;
}

hr {
    margin-top: calc(var(--sector-body-padding) * -2 + var(--sector-header-height) / 2);
    margin-bottom: 0;
    border: none;
    border-top: 1px solid black;
}

img.hero {
    width: 100%;
    max-height: var(--sector-hero-image-height);
    object-fit: cover;
    margin-bottom: calc(var(--sector-header-height) / 2);
}

div.share {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    margin-right: var(--gutter);
    gap: var(--social-media-gap);
}


@media (max-width: 960px) {
    h2 {
        padding: var(--gutter);
        line-height: 1em !important;
    }

    hr {
        margin-top: calc(var(--sector-body-padding) * -1 + var(--sector-header-height) / 2);
    }

    .row {
        padding-left: 0 !important;
        padding-right: 0 !important;
        flex-direction: column;
    }

    .column div >:deep(*) {
        padding-left: var(--gutter);
        padding-right: var(--gutter);
    }

    .column div :deep(blockquote p) {
        /* should match global h2 */
        font-size: 4vmax !important;
        line-height: 4vmax !important;
        letter-spacing: -0.075rem !important;
    }

    .column.left div >:deep(p:first-of-type) {
        /* opacity: 0.5; */
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    div.share {
        margin-left: var(--gutter);
    }
}
</style>