<template>
    <div v-if="visible" class="row horizontal">
        <span v-for="i in 12" class="col"></span>
    </div>
    <div v-if="false" class="row vertical">
        <span v-for="i in 12" class="col"></span>
    </div>
</template>

<script>
export default {
    data() {
        return { 
            visible: false,
        }
    },
    mounted() {
        window.addEventListener('keypress', e => {
            if (e.shiftKey && e.key == 'G') {
                this.visible = !this.visible
            }
        })
    }
}

</script>

<style scoped>
div.horizontal {
    position: absolute;
    width: var(--app-width);
    height: 100%;
    background-color: blue;
    z-index: 100;
    opacity: 0.125;
    pointer-events: none;
}

div.horizontal span {
    height: 100%;
    background-color: red;
    z-index: 101;
}

div.vertical {
    position: absolute;
    width: var(--app-width);
    height: 100%;
    background-color: blue;
    z-index: 100;
    opacity: 0.125;
    pointer-events: none;
    transform: translate(calc(var(--app-width)/1), 0) rotate(90deg) scale(1, 3);
    transform-origin: 0 0;
    overflow: hidden;
}

div.vertical span {
    height: 100%;
    background-color: red;
    z-index: 101;
}

</style>